import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { UsersCompany, CompanyUser, UserInformation, UserRoleLevels } from "@/app/lib/interfaces";
import { User } from "firebase/auth";
import { cleanupAllProjects } from "@/app/lib/store/slices/projects/projectsSlice";
import { cleanupAllCustomers } from "@/app/lib/store/slices/customers/customersSlice";
import { cleanupCompanyUsers } from "@/app/lib/store/slices/companyUsers/companyUsersSlice";
import { cleanupManufacturingFacilities } from "@/app/lib/store/slices/manufacturingFacilities/manufacturingFacilitiesSlice";
import { cleanupUsedSerialNumbers } from "@/app/lib/store/slices/usedSerialNumbers/usedSerialNumbersSlice";
import { cleanupAllProductionStages } from "@/app/lib/store/slices/productionStages/productionStagesSlice";
import { cleanupAllQualityChecks } from "@/app/lib/store/slices/qualityChecks/qualityChecksSlice";
import { getAuth } from "firebase/auth";
import { cleanupAllSuppliers } from "@/app/lib/store/slices/suppliers/suppliersSlice";
import { cleanupAllChecklists } from "@/app/lib/store/slices/checklists/checklistsSlice";
import { cleanupAllBatches } from "@/app/lib/store/slices/batches/batchesSlice";
import { cleanupAllAnswers } from "@/app/lib/store/slices/qualityChecks/qualityCheckAnswersSlice";
import { cleanupReservedSerialNumbers } from "./reservedSerialNumbers/reservedSerialNumbersSlice";
import { cleanupCompanyInformation } from "./companyInformation/companyInformationSlice";
import { fetchDocument, simpleUpdateDocument, updateDocumentsWithHistory } from "../../firebase/firebaseCRUD";
import { PATHS } from "../../firebase/constants";

export interface AppState {
  selectedCompany: UsersCompany | null;
  // user: User | null;
  userInformation: UserInformation | null;
  isInitialized: boolean;
  isLoading: boolean;
  error: string | null;
  operatingSystem: "windows" | "mac" | "other" | null;
  currentPageHasUnsavedChanges: boolean;
}

const initialState: AppState = {
  selectedCompany: null,
  userInformation: null,
  isInitialized: false,
  isLoading: false,
  error: null,
  operatingSystem: null,
  currentPageHasUnsavedChanges: false,
  // user: null,
};

// Helper function to detect operating system
const detectOperatingSystem = (): "windows" | "mac" | "other" => {
  if (typeof navigator === "undefined") return "other";

  const platform = navigator.platform.toLowerCase();

  if (platform.includes("win")) {
    return "windows";
  } else if (platform.includes("mac")) {
    return "mac";
  } else {
    return "other";
  }
};

// Async thunk for initializing the app
export const initializeApp = createAsyncThunk(
  "app/initialize",
  async (user: User | null, { dispatch }) => {
    if (!user) {
      return null;
    }
    const userInfo = await fetchDocument<UserInformation>(PATHS.documents.user(user.uid));

    if (!userInfo) {
      throw new Error("No user data found");
    }

    const companies = userInfo.companies;
    const previouslySelectedCompanyId = userInfo.previouslySelectedCompanyId;

    // Find the company to initialize data for
    const company = companies.find(
      (c: UsersCompany) =>
        c.isVerified &&
        (c.companyId === previouslySelectedCompanyId ||
          !previouslySelectedCompanyId),
    );

    if (!company) {
      return null;
    }

    return { userInfo, selectedCompany: company };
  },
);

// Async thunk for changing the selected company
export const changeSelectedCompany = createAsyncThunk(
  "app/changeSelectedCompany",
  async (company: UsersCompany, { getState, dispatch }) => {
    if (!company.isVerified) {
      throw new Error("Cannot select unverified company");
    }

    // Clean up all data from previous company
    await Promise.all([
      dispatch(cleanupAllProjects()),
      dispatch(cleanupAllCustomers()),
      dispatch(cleanupCompanyUsers()),
      dispatch(cleanupManufacturingFacilities()),
      dispatch(cleanupUsedSerialNumbers()),
      dispatch(cleanupAllProductionStages()),
      dispatch(cleanupAllQualityChecks()),
      dispatch(cleanupAllBatches()),
      dispatch(cleanupAllAnswers()),
      dispatch(cleanupAllSuppliers()),
      dispatch(cleanupAllChecklists()),
      dispatch(cleanupReservedSerialNumbers()),
      dispatch(cleanupCompanyInformation()),
    ]);

    // Get the current user's ID from Firebase Auth
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      throw new Error("No authenticated user found");
    }

    await simpleUpdateDocument({
      path: PATHS.documents.user(user.uid),
      data: {
        previouslySelectedCompanyId: company.companyId,
      },
    });

    return company;
  },
);

// Async thunk for verifying companies
export const verifyCompanies = createAsyncThunk(
  "app/verifyCompanies",
  async (userId: string, { getState }) => {
    // let currentUserInfo = (getState() as RootState).app.userInformation;

    // if (!currentUserInfo) {
    const currentUserInfo = await fetchDocument<UserInformation>(PATHS.documents.user(userId));
    // }

    if (!currentUserInfo?.companies) {
      return currentUserInfo;
    }

    const verifiedCompanies = await Promise.all(
      currentUserInfo.companies.map(async (company: UsersCompany) => {
        if (!company.isVerified) return company;

        const companyUserDocPath = PATHS.documents.companyUser(company.companyId, userId);

        // Add retry logic for fetching company user doc
        let retryCount = 0;
        let companyUserDoc = null;
        while (retryCount < 3) {
          companyUserDoc = await fetchDocument<CompanyUser>(companyUserDocPath);
          if (companyUserDoc) break;

          console.log(`[verifyCompanies] Retry ${retryCount + 1}/3: companyUserDoc not found`);
          await new Promise(resolve => setTimeout(resolve, 1000)); // 1 second delay
          retryCount++;
        }

        if (!companyUserDoc) {
          console.log("[verifyCompanies] companyUserDoc not found after 3 retries");
          return { ...company, isVerified: false };
        }
        if (companyUserDoc.isSoftDeleted) {
          console.log("[verifyCompanies] companyUserDoc is soft deleted");
          return { ...company, isVerified: false };
        }
        if (companyUserDoc.role === UserRoleLevels.NOT_AUTHORIZED) {
          console.log("[verifyCompanies] companyUserDoc role is not authorized");
          return { ...company, isVerified: false };
        }
        if (companyUserDoc.role !== company.userRole) {
          console.log("[verifyCompanies] companyUserDoc role is not the same as the company user role");
          return { ...company, isVerified: false };
        }

        return company;
      }),
    );

    const updatedUserInfo: UserInformation = {
      ...currentUserInfo,
      companies: verifiedCompanies,
    };

    // Update user document with verified companies
    await updateDocumentsWithHistory([{
      path: PATHS.documents.user(userId),
      oldData: currentUserInfo,
      newData: updatedUserInfo,
      userId,
    }]);

    return updatedUserInfo;
  },
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setOperatingSystem: (state, action: PayloadAction<"windows" | "mac" | "other" | null>) => {
      state.operatingSystem = action.payload;
    },
    setCurrentPageHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.currentPageHasUnsavedChanges = action.payload;
    },
    // setUser: (state, action: PayloadAction<User | null>) => {
    //   state.user = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // Initialize app
    builder
      .addCase(initializeApp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeApp.fulfilled, (state, action) => {
        if (action.payload) {
          state.userInformation = action.payload.userInfo;
          state.selectedCompany = action.payload.selectedCompany;
          state.operatingSystem = detectOperatingSystem();
          // state.user = action.meta.arg;
        } else {
          state.userInformation = null;
          state.selectedCompany = null;
          state.operatingSystem = detectOperatingSystem(); // Still detect OS even if no user
          // state.user = null;
        }
        state.isLoading = false;
        state.isInitialized = true;
      })
      .addCase(initializeApp.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
        state.isInitialized = true;
      });

    // Change selected company
    builder
      .addCase(changeSelectedCompany.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeSelectedCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload;
        state.isLoading = false;
      })
      .addCase(changeSelectedCompany.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })

      // Verify companies
      .addCase(verifyCompanies.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyCompanies.fulfilled, (state, action) => {
        if (action.payload) {
          state.userInformation = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(verifyCompanies.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      });
  },
});

export const { setInitialized, setOperatingSystem, setCurrentPageHasUnsavedChanges } = appSlice.actions;

export default appSlice.reducer;
