import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  QualityCheck,
  Answer,
  ProductionStage,
  Batch,
  ChecklistItem,
  UsedSerialNumber,
  Order,
} from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import { addUsedSerialNumber } from "@/app/lib/store/slices/usedSerialNumbers/usedSerialNumbersSlice";
import { setDocumentWithHistory, updateDocumentsWithHistory, fetchDocument, fetchCollection } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp, OrderByDirection } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectQualityCheck } from "./qualityChecksSelectors";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [projectId: string]: CollectionSubscription<QualityCheck> | null } = {};

// Track in-progress quality check fetches to prevent duplicate fetches
let inProgressFetches: { [checkId: string]: Promise<QualityCheck | null> } = {};

export interface QualityChecksState {
  items: {
    [projectId: string]: QualityCheck[];
  };
  allFetchedItemsPerProject: {
    [projectId: string]: {
      lastFetchedAt: number;
      items: QualityCheck[];
    };
  };
  manuallyFetchedItems: {
    [projectId: string]: QualityCheck[];
  };
  isLoading: {
    [projectId: string]: boolean;
  };
  error: {
    [projectId: string]: string | null;
  };
  currentQualityCheck: CurrentQualityCheckState;
}

export interface CurrentQualityCheckState {
  check: QualityCheck | null;
  stage: ProductionStage | null;
  batch: Batch | null;
  order: Order | null;
  checklist: ChecklistItem[];
  answers?: Answer[];
  status?: "idle" | "loading" | "succeeded" | "failed";
  error?: string | null;
}

const initialState: QualityChecksState = {
  items: {},
  allFetchedItemsPerProject: {},
  isLoading: {},
  error: {},
  currentQualityCheck: {
    check: null,
    stage: null,
    batch: null,
    order: null,
    checklist: [],
    status: "idle",
    error: null,
  },
  manuallyFetchedItems: {},
};

export const createQualityCheck = createAsyncThunk(
  "qualityChecks/create",
  async (
    {
      projectId,
      qualityCheck,
    }: {
      projectId: string;
      qualityCheck: Omit<QualityCheck, "id">;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.collections.qualityChecks(companyId);
    const newQualityCheck = await setDocumentWithHistory<QualityCheck>({
      path,
      data: qualityCheck,
      userId
    });

    const usedSerialNumber: Omit<UsedSerialNumber, "id"> = {
      serialNumber: qualityCheck.serialNumber,
      projectId,
      stageId: qualityCheck.stageId,
      batchId: qualityCheck.batchId,
    }

    await dispatch(
      addUsedSerialNumber({
        usedSerialNumber,
      }),
    ).unwrap();

    return { projectId, check: newQualityCheck };
  },
);

export const updateQualityCheck = createAsyncThunk(
  "qualityChecks/update",
  async ({
    projectId,
    checkId,
    partialUpdate: updatedQualityCheck,
  }: {
    projectId: string;
    checkId: string;
    partialUpdate: Partial<QualityCheck>;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const currentQualityCheck = state.qualityChecks.items[projectId]?.find((check) => check.id === checkId);

    if (!currentQualityCheck) {
      throw new Error("Quality check not found");
    }

    const updatedQualityCheckWithId = {
      ...currentQualityCheck,
      ...updatedQualityCheck,
      id: checkId,
    }

    const path = PATHS.documents.qualityCheck(companyId, checkId);

    await updateDocumentsWithHistory([{
      path,
      oldData: currentQualityCheck,
      newData: updatedQualityCheckWithId,
      userId
    }]);

    return { projectId, check: updatedQualityCheck };
  }
);

export const initializeLatest20QualityChecksForProject = createAsyncThunk(
  "qualityChecks/initialize",
  async (projectId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this project
    if (subscriptions[projectId]) {
      return state.qualityChecks.items[projectId] || [];
    }

    const collectionPath = PATHS.collections.qualityChecks(companyId);
    const queryConstraints = [
      { field: "projectId", operator: "==" as WhereFilterOp, value: projectId },
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "lastUpdatedAt", direction: "desc" as OrderByDirection, type: "orderBy" as const },
      { type: "limit" as const, value: 20 },
    ];

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<QualityCheck>(
        collectionPath,
        (checks) => {
          dispatch(setQualityChecks({ projectId, checks }));
        },
        (error) => {
          console.error("Error fetching quality checks:", error);
          dispatch(setQualityChecks({ projectId, checks: [] }));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[projectId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setQualityChecks({ projectId, checks: [] }));
    }

    return state.qualityChecks.items[projectId] || [];
  }
);

export const cleanupQualityChecks = createAsyncThunk(
  "qualityChecks/cleanup",
  async (projectId: string) => {
    if (subscriptions[projectId]) {
      subscriptions[projectId]!.unsubscribe();
      subscriptions[projectId] = null;
    }
  },
);

export const cleanupAllQualityChecks = createAsyncThunk(
  "qualityChecks/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((projectId) => {
      if (subscriptions[projectId]) {
        subscriptions[projectId]!.unsubscribe();
        subscriptions[projectId] = null;
      }
    });
    subscriptions = {};
  },
);

export const fetchQualityChecksForSerialNumber = createAsyncThunk(
  "qualityChecks/fetchForSerialNumber",
  async (
    { serialNumber }: { serialNumber: string },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    const collectionPath = PATHS.collections.qualityChecks(companyId);
    const queryConstraints = [
      { field: "serialNumber", operator: "==" as WhereFilterOp, value: serialNumber },
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
    ];

    return await fetchCollection<QualityCheck>({
      collectionPath,
      queryConstraints
    });
  },
);

export const fetchAllQualityChecksForProject = createAsyncThunk(
  "qualityChecks/fetchAllForProject",
  async (projectId: string, { getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId) {
      throw new Error("No company selected");
    }

    const collectionPath = PATHS.collections.qualityChecks(companyId);
    const queryConstraints = [
      { field: "projectId", operator: "==" as WhereFilterOp, value: projectId },
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "lastUpdatedAt", direction: "desc" as OrderByDirection, type: "orderBy" as const },
    ];

    return await fetchCollection<QualityCheck>({
      collectionPath,
      queryConstraints
    });
  },
);

export const fetchQualityCheckById = createAsyncThunk(
  "qualityChecks/fetchById",
  async (
    { checkId }: { checkId: string },
    { getState, dispatch },
  ): Promise<QualityCheck | null> => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId) {
      throw new Error("No company selected. QS:1");
    }
    // 1. Check if the check exists in the store
    const checkFromStore: QualityCheck | null = selectQualityCheck(checkId)(state);
    if (checkFromStore) {
      return checkFromStore;
    }

    // 2. Check if there's already a fetch in progress for this check
    if (checkId in inProgressFetches) {
      return inProgressFetches[checkId];
    }

    // 3. Start a new fetch and track it
    try {
      const path = PATHS.documents.qualityCheck(companyId, checkId);

      // Create and store the promise
      const fetchPromise = fetchDocument<QualityCheck>(path)
        .then(check => {
          if (check) {
            dispatch(addManuallyFetchedQualityCheckToStore({ projectId: check.projectId, check }));
          }
          // Clean up the in-progress tracking when completed
          delete inProgressFetches[checkId];
          return check;
        })
        .catch(error => {
          // Clean up on error too
          delete inProgressFetches[checkId];
          throw error;
        });

      // Store the promise for potential duplicate requests
      inProgressFetches[checkId] = fetchPromise;

      // Wait for the result
      return await fetchPromise;
    } catch (error) {
      // Ensure cleanup in case of errors outside the promise chain
      delete inProgressFetches[checkId];
      throw error;
    }
  },
);

// Helper function to check if a project has an active subscription
export const hasActiveSubscription = (projectId: string) => {
  return !!subscriptions[projectId];
};

const qualityChecksSlice = createSlice({
  name: "qualityChecks",
  initialState,
  reducers: {
    setQualityChecks: (
      state,
      action: PayloadAction<{ projectId: string; checks: QualityCheck[] }>,
    ) => {
      const { projectId, checks } = action.payload;
      state.items[projectId] = checks;
      state.error[projectId] = null;
    },
    updateQualityCheck: (
      state,
      action: PayloadAction<{ projectId: string; check: QualityCheck }>,
    ) => {
      const { projectId, check } = action.payload;
      const index = state.items[projectId]?.findIndex((c) => c.id === check.id);
      if (index !== undefined && index !== -1) {
        state.items[projectId][index] = check;
      }
    },
    removeQualityCheck: (
      state,
      action: PayloadAction<{ projectId: string; checkId: string }>,
    ) => {
      const { projectId, checkId } = action.payload;
      const checks = state.items[projectId];
      if (checks) {
        state.items[projectId] = checks.filter((check) => check.id !== checkId);
      }
    },
    setCurrentQualityCheck: (
      state,
      action: PayloadAction<CurrentQualityCheckState>,
    ) => {
      state.currentQualityCheck = {
        ...state.currentQualityCheck,
        ...action.payload,
        status: "succeeded",
        error: null,
      };
    },
    clearCurrentQualityCheck: (state) => {
      state.currentQualityCheck = initialState.currentQualityCheck;
    },
    updateCurrentQualityCheck: (
      state,
      action: PayloadAction<Partial<CurrentQualityCheckState>>,
    ) => {
      state.currentQualityCheck = {
        ...state.currentQualityCheck,
        ...action.payload,
      };
    },
    addManuallyFetchedQualityCheckToStore: (
      state,
      action: PayloadAction<{ projectId: string; check: QualityCheck }>,
    ) => {
      const { projectId, check } = action.payload;

      state.manuallyFetchedItems[projectId] = [...(state.manuallyFetchedItems[projectId] || []), check];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeLatest20QualityChecksForProject.pending, (state, action) => {
        const projectId = action.meta.arg;
        if (!state.items[projectId]) {
          state.items[projectId] = [];
        }
        state.isLoading[projectId] = true;
        state.error[projectId] = null;
      })
      .addCase(initializeLatest20QualityChecksForProject.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        state.isLoading[projectId] = false;
      })
      .addCase(initializeLatest20QualityChecksForProject.rejected, (state, action) => {
        const projectId = action.meta.arg;
        state.error[projectId] = action.error.message || "Unknown error";
        state.isLoading[projectId] = false;
      })
      .addCase(cleanupQualityChecks.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        delete state.items[projectId];
        delete state.isLoading[projectId];
        delete state.error[projectId];
      })
      .addCase(cleanupAllQualityChecks.fulfilled, (state) => {
        state.items = {};
        state.isLoading = {};
        state.error = {};
        state.currentQualityCheck = initialState.currentQualityCheck;
      })
      .addCase(fetchAllQualityChecksForProject.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        state.allFetchedItemsPerProject[projectId] = {
          lastFetchedAt: new Date().getTime(),
          items: action.payload,
        };
      });
  },
});

export const {
  setQualityChecks,
  updateQualityCheck: updateQualityCheckAction,
  removeQualityCheck,
  setCurrentQualityCheck,
  clearCurrentQualityCheck,
  updateCurrentQualityCheck,
  addManuallyFetchedQualityCheckToStore,
} = qualityChecksSlice.actions;

export default qualityChecksSlice.reducer;

// Selectors
export const selectCurrentQualityCheck = (state: RootState) =>
  state.qualityChecks.currentQualityCheck;
