import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Base selector
const selectCustomersState = (state: RootState) => state.customers;

// Select all customers as an object
export const selectCustomersItems = createSelector(
  selectCustomersState,
  (state) => state.items,
);

// Select all customers as an array
export const selectCustomersArray = createSelector(
  selectCustomersItems,
  (items) => Object.values(items),
);

// Select loading state
export const selectCustomersLoading = createSelector(
  selectCustomersState,
  (state) => state.isLoading,
);

// Select error state
export const selectCustomersError = createSelector(
  selectCustomersState,
  (state) => state.error,
);

// Select a single customer by ID
export const selectCustomerById = (customerId: string) =>
  createSelector(selectCustomersItems, (items) => items[customerId] || null);
