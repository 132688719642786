import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChecklistItem } from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectChecklistItemById } from "@/app/lib/store/slices/checklists/checklistsSelectors";
import { PATHS } from "@/app/lib/firebase/constants";
import { fetchCollection, setDocumentWithHistory, softDeleteDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";

export interface ChecklistsState {
  items: {
    [productionStageId: string]: ChecklistItem[];
  };
  isLoading: {
    [productionStageId: string]: boolean;
  };
  error: {
    [productionStageId: string]: string | null;
  };
}

/**
 * Fetches all checklist items for a given production stage.
 * If the items are already in the Redux store, returns them from there.
 * Otherwise, fetches from Firestore and updates the store.
 *
 * @param projectId - The ID of the project
 * @param productionStageId - The ID of the production stage to fetch checklist items for
 * @returns Promise resolving to array of ChecklistItem objects
 * @throws Error if no company is selected
 */
export const getAllChecklistItems = createAsyncThunk(
  "checklists/getAll",
  async (
    {
      forceFetch,
      projectId,
      productionStageId,
    }: { forceFetch?: boolean, projectId: string; productionStageId: string },
    { getState }
  ) => {
    const state = getState() as RootState;
    const stateChecklist = state.checklists.items[productionStageId];
    if (stateChecklist && !forceFetch) {
      return stateChecklist;
    }

    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const checklist = await fetchCollection<ChecklistItem>(
      {
        collectionPath: PATHS.collections.checklist(companyId, projectId, productionStageId),
        queryConstraints: [
          {
            field: "isSoftDeleted",
            operator: "==",
            value: false
          },
          {
            field: "order",
            type: "orderBy",
            direction: "asc"
          }
        ]
      }
    );
    return checklist;
  },
);

export const updateChecklistItem = createAsyncThunk<void, {
  projectId: string;
  productionStageId: string;
  checklistItem: ChecklistItem;
}, {
  rejectValue: {
    productionStageId: string;
    originalItem: ChecklistItem;
  }
}>(
  "checklists/updateChecklistItem",
  async (
    {
      projectId,
      productionStageId,
      checklistItem,
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const originalChecklistItem = selectChecklistItemById(productionStageId, checklistItem.id)(state);
    if (!originalChecklistItem) {
      throw new Error("[checklists/updateChecklistItem] Original checklist item not found");
    }
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId || !userId) {
      throw new Error("[checklists/updateChecklistItem] No company or user selected");
    }
    try {
      await updateDocumentsWithHistory<ChecklistItem>([
        {
          path: PATHS.documents.checklist(companyId, projectId, productionStageId, checklistItem.id),
          oldData: originalChecklistItem,
          newData: checklistItem,
          userId
        }
      ]);
    } catch (error) {
      return rejectWithValue({ productionStageId, originalItem: originalChecklistItem });
    }
  }
);

export const addChecklistItem = createAsyncThunk(
  "checklists/addChecklistItem",
  async (
    {
      projectId,
      productionStageId,
      checklistItem,
    }: {
      projectId: string;
      productionStageId: string;
      checklistItem: Omit<ChecklistItem, "id">;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }
    const checklistItemWithId = await setDocumentWithHistory({
      path: PATHS.collections.checklist(companyId, projectId, productionStageId),
      data: checklistItem,
      userId
    });
    return checklistItemWithId;
  },
);

export const deleteChecklistItem = createAsyncThunk(
  "checklists/deleteChecklistItem",
  async (
    {
      projectId,
      productionStageId,
      checklistItemId,
    }: {
      projectId: string;
      productionStageId: string;
      checklistItemId: string;
    },
    { getState },
  ) => {
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(getState() as RootState);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }
    await softDeleteDocumentWithHistory({
      path: PATHS.documents.checklist(companyId, projectId, productionStageId, checklistItemId),
      userId,
    });
  },
);



// FIXED UNTIL ALL ABOVE

export const moveChecklistItem = createAsyncThunk(
  "checklists/moveChecklistItem",
  async (
    {
      projectId,
      productionStageId,
      checklistItemId,
      direction,
    }: {
      projectId: string;
      productionStageId: string;
      checklistItemId: string;
      direction: "up" | "down";
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const checklist = state.checklists.items[productionStageId];
    const currentIndex = checklist.findIndex(item => item.id === checklistItemId);

    if (currentIndex === -1) {
      throw new Error("Checklist item not found");
    }
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(getState() as RootState);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    if ((direction === "up" && currentIndex === 0) ||
      (direction === "down" && currentIndex === checklist.length - 1)) {
      return; // Can't move further in this direction
    }

    const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

    const currentChecklistItem = checklist[currentIndex];
    const swapChecklistItem = checklist[newIndex];

    const swapItemOrder = swapChecklistItem.order ?? 0;
    let currentItemOrder = currentChecklistItem.order ?? 0;
    if (swapItemOrder === currentItemOrder) {
      currentItemOrder = direction === "up" ? swapItemOrder + 1 : swapItemOrder - 1
    }

    const swapChecklistItemUpdated = { ...swapChecklistItem, order: currentItemOrder };
    const currentChecklistItemUpdated = { ...currentChecklistItem, order: swapItemOrder };

    await updateDocumentsWithHistory<ChecklistItem>([
      {
        path: PATHS.documents.checklist(companyId, projectId, productionStageId, currentChecklistItem.id),
        oldData: currentChecklistItem,
        newData: currentChecklistItemUpdated,
        userId
      },
      {
        path: PATHS.documents.checklist(companyId, projectId, productionStageId, swapChecklistItem.id),
        oldData: swapChecklistItem,
        newData: swapChecklistItemUpdated,
        userId
      }
    ]);

    return {
      swapChecklistItemUpdated,
      currentChecklistItemUpdated
    }
  }
);

export const cleanupAllChecklists = createAsyncThunk(
  "checklists/cleanupAll",
  async () => {
    // Reset all checklists data
  }
);

const initialState: ChecklistsState = {
  items: {},
  isLoading: {},
  error: {},
};

const checklistsSlice = createSlice({
  name: "checklists",
  initialState,
  reducers: {
    setAllChecklistItems: (
      state,
      action: PayloadAction<{
        productionStageId: string;
        checklist: ChecklistItem[];
      }>,
    ) => {
      const { productionStageId, checklist } = action.payload;
      const sortedChecklist = checklist.sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0),
      );
      state.items[productionStageId] = sortedChecklist;
    },
    addChecklistItemInStore: (
      state,
      action: PayloadAction<{
        productionStageId: string;
        checklistItem: ChecklistItem;
      }>,
    ) => {
      const { productionStageId, checklistItem } = action.payload;
      const checklist = state.items[productionStageId];
      checklist.push(checklistItem);
    },
    removeChecklistItemInStore: (
      state,
      action: PayloadAction<{
        productionStageId: string;
        checklistItemId: string;
      }>,
    ) => {
      const { productionStageId, checklistItemId } = action.payload;
      const checklist = state.items[productionStageId];
      const index = checklist.findIndex((item) => item.id === checklistItemId);
      checklist.splice(index, 1);
    },
    replaceChecklistItemOptimistic: (
      state,
      action: PayloadAction<{
        productionStageId: string;
        optimisticChecklistItemId: string;
        checklistItem: ChecklistItem;
      }>,
    ) => {
      const { productionStageId, optimisticChecklistItemId, checklistItem } =
        action.payload;
      const checklist = state.items[productionStageId];
      const index = checklist.findIndex(
        (item) => item.id === optimisticChecklistItemId,
      );
      checklist[index] = checklistItem;
    },
    updateChecklistItemInStore: (
      state,
      action: PayloadAction<{
        productionStageId: string;
        checklistItemId: string;
        checklistItem: Partial<ChecklistItem>;
      }>,
    ) => {
      const { productionStageId, checklistItemId, checklistItem } =
        action.payload;
      const checklist = state.items[productionStageId];
      const index = checklist.findIndex((item) => item.id === checklistItemId);
      if (index !== -1) {
        checklist[index] = {
          ...checklist[index],
          ...checklistItem,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllChecklistItems
      .addCase(getAllChecklistItems.pending, (state, action) => {
        const productionStageId = action.meta.arg.productionStageId;
        state.isLoading[productionStageId] = true;
        state.error[productionStageId] = null;
      })
      .addCase(getAllChecklistItems.fulfilled, (state, action) => {
        const productionStageId = action.meta.arg.productionStageId;
        state.items[productionStageId] = action.payload;
        state.isLoading[productionStageId] = false;
        state.error[productionStageId] = null;
      })
      .addCase(getAllChecklistItems.rejected, (state, action) => {
        const productionStageId = action.meta.arg.productionStageId;
        state.isLoading[productionStageId] = false;
        state.error[productionStageId] = action.error.message ?? 'An error occurred';
      })
      // updateChecklistItem
      .addCase(updateChecklistItem.fulfilled, (state, action) => {
        const { productionStageId, checklistItem } = action.meta.arg;
        const checklist = state.items[productionStageId];
        const index = checklist.findIndex(item => item.id === checklistItem.id);
        if (index !== -1) {
          checklist[index] = checklistItem;
        }
      })
      .addCase(updateChecklistItem.rejected, (state, action) => {
        if (action.payload) {
          const { productionStageId, originalItem } = action.payload;
          const checklist = state.items[productionStageId];
          const index = checklist.findIndex(item => item.id === originalItem.id);
          if (index !== -1) {
            checklist[index] = originalItem;
          }
        }
      })
      // addChecklistItem
      .addCase(addChecklistItem.fulfilled, (state, action) => {
        const { productionStageId } = action.meta.arg;
        const checklist = state.items[productionStageId];
        if (action.payload) {
          checklist.push(action.payload);
        }
      })
      // deleteChecklistItem
      .addCase(deleteChecklistItem.fulfilled, (state, action) => {
        const { productionStageId, checklistItemId } = action.meta.arg;
        const checklist = state.items[productionStageId];
        const index = checklist.findIndex(item => item.id === checklistItemId);
        if (index !== -1) {
          checklist.splice(index, 1);
        }
      })
      // moveChecklistItem
      .addCase(moveChecklistItem.fulfilled, (state, action) => {
        const { productionStageId } = action.meta.arg;
        const checklist = state.items[productionStageId];
        if (action.payload) {
          const { currentChecklistItemUpdated, swapChecklistItemUpdated } = action.payload;
          const currentIndex = checklist.findIndex(item => item.id === currentChecklistItemUpdated.id);
          const swapIndex = checklist.findIndex(item => item.id === swapChecklistItemUpdated.id);
          if (currentIndex !== -1 && swapIndex !== -1) {
            checklist[currentIndex] = currentChecklistItemUpdated;
            checklist[swapIndex] = swapChecklistItemUpdated;
          }
        }
      })
      // cleanupAllChecklists
      .addCase(cleanupAllChecklists.fulfilled, (state) => {
        state.items = {};
        state.isLoading = {};
        state.error = {};
      });
  },
});

export const {
  // setAllChecklistItems,
  // addChecklistItemInStore,
  // removeChecklistItemInStore,
  // replaceChecklistItemOptimistic,
  // updateChecklistItemInStore,
} = checklistsSlice.actions;

export default checklistsSlice.reducer;
