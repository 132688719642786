import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";
import { QualityCheck } from "@/app/lib/interfaces";
import { DateService } from "@/app/lib/services/date.service";

// Base selector
const selectQualityChecksState = (state: RootState) => state.qualityChecks;

// Select all quality checks for a specific project
export const selectProjectQualityChecks = (projectId: string) =>
  createSelector(
    selectQualityChecksState,
    (state) => state.items[projectId] || [],
  );

export const selectAllQualityChecksForProject = (projectId: string) =>
  createSelector(
    selectQualityChecksState,
    (state) => state.allFetchedItemsPerProject[projectId],
  );

export const selectRecentProjectQualityChecks = (projectId: string) =>
  createSelector(selectProjectQualityChecks(projectId), (checks) =>
    [...(checks || [])]
      .sort(
        (a, b) =>
          DateService.parseDate(b.lastUpdatedAt ?? "").getTime() -
          DateService.parseDate(a.lastUpdatedAt ?? "").getTime(),
      )
      .slice(0, 20),
  );

// Select loading state for a specific project
export const selectQualityChecksLoading = (projectId: string) =>
  createSelector(
    selectQualityChecksState,
    (state) => state.isLoading[projectId] || false,
  );

// Select error state for a specific project
export const selectQualityChecksError = (projectId: string) =>
  createSelector(
    selectQualityChecksState,
    (state) => state.error[projectId] || null,
  );

// Select a specific quality check by ID
export const selectQualityCheck = (checkId: string) =>
  createSelector(
    selectQualityChecksState,
    (state) => {
      let check = Object.values(state.items).flat().find(check => check.id === checkId);
      if (check) {
        return check;
      }
      for (const projectId in state.allFetchedItemsPerProject) {
        if (state.allFetchedItemsPerProject[projectId]?.items) {
          check = state.allFetchedItemsPerProject[projectId].items.find(item => item.id === checkId);
          if (check) break;
        }
      }
      if (check) {
        return check;
      }
      check = Object.values(state.manuallyFetchedItems).flat().find(check => check.id === checkId);

      return check || null;
    }
  );

// Select quality checks by serial number
export const selectQualityChecksBySerialNumber = (
  projectId: string,
  serialNumber: string,
) =>
  createSelector(selectProjectQualityChecks(projectId), (checks) =>
    checks.filter((check) => check.serialNumber === serialNumber),
  );

// Select latest quality check for a serial number
export const selectLatestQualityCheckForSerialNumber = (
  projectId: string,
  serialNumber: string,
) =>
  createSelector(
    selectQualityChecksBySerialNumber(projectId, serialNumber),
    (checks) => {
      if (checks.length === 0) return null;
      return checks.reduce(
        (latest, current) => {
          if (!latest) return current;
          return DateService.parseDate(current.lastUpdatedAt ?? "") > DateService.parseDate(latest.lastUpdatedAt ?? "")
            ? current
            : latest;
        },
        null as QualityCheck | null,
      );
    },
  );
