import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateService } from "@/app/lib/services/date.service";
import { Supplier } from "@/app/lib/suppliersDBInterfaces";
import { RootState } from "@/app/lib/store/store";
import { updateSupplier as updateManufacturingFacilitySupplier } from "@/app/lib/store/slices/manufacturingFacilities/manufacturingFacilitiesSlice";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { PATHS } from "@/app/lib/firebase/constants";
import { fetchCollection, setDocumentWithHistory, updateDocumentsWithHistory, subscribeToDocumentWithRetry, fetchDocument } from "@/app/lib/firebase/firebaseCRUD";
import { selectSupplierById } from "./suppliersSelectors";

export interface SuppliersState {
    suppliersList: Supplier[];
    loading: boolean;
    error: string | null;
    lastTimeFetched: string | null;
    searchResults: Supplier[];
}

export const fetchAllSuppliers = createAsyncThunk(
    "suppliers/fetchAll",
    async (forceFetch: boolean = false, { getState }) => {
        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId) {
            throw new Error("No company selected");
        }

        if (!forceFetch && state.suppliers.lastTimeFetched) {
            // If we have suppliers and they were fetched less than 20 minutes ago, return them
            const fetchedMoreThanXMinAgo = DateService.getSecondsSince(state.suppliers.lastTimeFetched) > 20 * 60;
            if (!fetchedMoreThanXMinAgo) {
                return {
                    suppliers: state.suppliers.suppliersList,
                    fromCache: true
                };
            }
        }

        const suppliers = await fetchCollection<Supplier>(
            {
                collectionPath: PATHS.collections.suppliers(companyId),
                queryConstraints: [
                    {
                        field: "isSoftDeleted",
                        operator: "==",
                        value: false
                    }
                ]
            }
        );

        return {
            suppliers,
            fromCache: false
        };
    }
);

export const fetchSupplierById = createAsyncThunk(
    "suppliers/fetchById",
    async ({
        supplierId,
        forceFetch
    }: {
        supplierId: string,
        forceFetch?: boolean
    }, { getState }) => {
        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId) {
            throw new Error("No company selected");
        }

        if (!forceFetch) {
            const stateSupplier = selectSupplierById(supplierId)(state);
            if (stateSupplier) {
                return stateSupplier;
            }
        }

        const supplier = await fetchDocument<Supplier>(PATHS.documents.supplier(companyId, supplierId));
        return supplier;
    }
);

export const addSupplier = createAsyncThunk(
    "suppliers/addSupplier",
    async (
        {
            supplier,
        }: {
            supplier: Omit<Supplier, "id">;
        },
        { getState }
    ) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const supplierWithoutUndefined = {
            ...supplier,
            nameLowerCase: supplier.name.toLowerCase(),
        };

        const newSupplier = await setDocumentWithHistory<Supplier>({
            path: PATHS.collections.suppliers(companyId),
            data: supplierWithoutUndefined,
            userId
        });

        return newSupplier as Supplier;
    }
);

export const updateSupplier = createAsyncThunk(
    "suppliers/updateSupplier",
    async (
        {
            newSupplier,
        }: {
            newSupplier: Supplier;
        },
        { getState }
    ) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const originalSupplier = selectSupplierById(newSupplier.id)(state);
        if (!originalSupplier) {
            throw new Error("Supplier not found");
        }

        const updateData = {
            ...newSupplier,
            nameLowerCase: newSupplier.name.toLowerCase(),
        };

        await updateDocumentsWithHistory<Supplier>([{
            path: PATHS.documents.supplier(companyId, newSupplier.id),
            oldData: originalSupplier,
            newData: { ...originalSupplier, ...updateData },
            userId
        }]);

        return {
            newSupplier
        };
    }
);

// Track active subscriptions for individual suppliers
let supplierSubscriptions: { [key: string]: () => void } = {};

export const subscribeToSupplierById = createAsyncThunk(
    "suppliers/subscribeToSupplierById",
    async (supplierId: string, { getState, dispatch }) => {
        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);
        console.log("[subscribeToSupplierById] supplierId: ", supplierId);

        if (!companyId) {
            throw new Error("No company selected");
        }

        // If already subscribed, don't create a new subscription
        if (supplierSubscriptions[supplierId]) {
            const existingSupplier = state.manufacturingFacilities.suppliers[supplierId];
            if (existingSupplier) {
                return existingSupplier;
            }
        }

        return new Promise<Supplier>((resolve, reject) => {
            try {
                const unsubscribe = subscribeToDocumentWithRetry<Supplier>(
                    PATHS.documents.supplier(companyId, supplierId),
                    (supplier) => {
                        if (supplier) {
                            console.log("supplier: ", supplier);
                            dispatch(updateManufacturingFacilitySupplier(supplier));
                            resolve(supplier);
                        } else {
                            reject(new Error(`Supplier with id ${supplierId} not found`));
                        }
                    },
                    (error) => {
                        console.error("Error fetching supplier:", error);
                        reject(error);
                    }
                );

                // Store the unsubscribe function
                supplierSubscriptions[supplierId] = unsubscribe;
            } catch (error) {
                console.error("Error setting up supplier subscription:", error);
                reject(error);
            }
        });
    }
);

export const cleanupSupplierSubscription = createAsyncThunk(
    "suppliers/cleanupSubscription",
    async (supplierId: string) => {
        if (supplierSubscriptions[supplierId]) {
            supplierSubscriptions[supplierId]();
            delete supplierSubscriptions[supplierId];
        }
        return supplierId;
    }
);

export const cleanupAllSuppliers = createAsyncThunk(
    "suppliers/cleanupAll",
    async () => {
        Object.values(supplierSubscriptions).forEach(unsubscribe => unsubscribe());
        supplierSubscriptions = {};
    }
);


export const searchSuppliersByName = createAsyncThunk(
    "suppliers/searchByName",
    async (searchText: string, { getState }) => {
        if (!searchText.trim()) {
            return [];
        }

        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId) {
            throw new Error("No company selected");
        }

        const searchQueryLower = searchText.toLowerCase();
        const suppliers = await fetchCollection<Supplier>(
            {
                collectionPath: PATHS.collections.suppliers(companyId),
                queryConstraints: [
                    {
                        field: "nameLowerCase",
                        operator: ">=",
                        value: searchQueryLower
                    },
                    {
                        field: "nameLowerCase",
                        operator: "<=",
                        value: searchQueryLower + '\uf8ff'
                    },
                    {
                        type: 'limit',
                        value: 10
                    }
                ]
            }
        );

        return suppliers;
    }
);

const initialState: SuppliersState = {
    suppliersList: [],
    loading: false,
    error: null,
    lastTimeFetched: null,
    searchResults: [],
};

const suppliersSlice = createSlice({
    name: "suppliers",
    initialState,
    reducers: {
        updateSupplierInStore: (state, action: PayloadAction<Supplier>) => {
            const index = state.suppliersList.findIndex(s => s.id === action.payload.id);
            if (index !== -1) {
                state.suppliersList[index] = action.payload;
            } else {
                state.suppliersList.push(action.payload);
            }
        },
        clearSuppliersList: (state) => {
            state.suppliersList = [];
            state.lastTimeFetched = null;
            state.searchResults = [];
        },
        clearSearchResults: (state) => {
            state.searchResults = [];
        }
    },
    extraReducers: (builder) => {
        // Fetch all suppliers
        builder
            .addCase(fetchAllSuppliers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllSuppliers.fulfilled, (state, action) => {
                state.loading = false;
                state.suppliersList = action.payload.suppliers;
                if (!action.payload.fromCache) {
                    state.lastTimeFetched = DateService.now();
                }
            })
            .addCase(fetchAllSuppliers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch suppliers";
            })

        // Add supplier
        builder
            .addCase(addSupplier.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addSupplier.fulfilled, (state, action) => {
                state.loading = false;
                state.suppliersList.push(action.payload);
            })
            .addCase(addSupplier.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to add supplier";
            })

        // Update supplier
        builder
            .addCase(updateSupplier.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSupplier.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.suppliersList.findIndex(
                    (supplier) => supplier.id === action.payload.newSupplier.id
                );
                if (index !== -1) {
                    state.suppliersList[index] = {
                        ...state.suppliersList[index],
                        ...action.payload,
                    };
                }
            })
            .addCase(updateSupplier.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to update supplier";
            })

        // Get supplier by ID
        builder
            .addCase(subscribeToSupplierById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(subscribeToSupplierById.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(subscribeToSupplierById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch supplier";
            })

        // Cleanup supplier subscription
        builder
            .addCase(cleanupSupplierSubscription.fulfilled, (state, action) => {
                state.suppliersList = state.suppliersList.filter(s => s.id !== action.payload);
            })

        // Search suppliers
        builder
            .addCase(searchSuppliersByName.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchSuppliersByName.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload;
            })
            .addCase(searchSuppliersByName.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to search suppliers";
            })

        // Cleanup all suppliers
        builder
            .addCase(cleanupAllSuppliers.fulfilled, (state) => {
                state.suppliersList = [];
                state.loading = false;
                state.error = null;
                state.lastTimeFetched = null;
                state.searchResults = [];
            });

        builder
            .addCase(fetchSupplierById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSupplierById.fulfilled, (state, action) => {
                state.loading = false;
                if (!action.payload) {
                    return;
                }
                const index = state.suppliersList.findIndex(s => s.id === action.payload?.id);
                if (index !== -1) {
                    state.suppliersList[index] = action.payload;
                } else {
                    state.suppliersList.push(action.payload);
                }
            })
            .addCase(fetchSupplierById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch supplier";
            })
    },
});

export const { updateSupplierInStore, clearSuppliersList, clearSearchResults } = suppliersSlice.actions;
export default suppliersSlice.reducer;
