import { configureStore } from "@reduxjs/toolkit";
import projectsReducer from "@/app/lib/store/slices/projects/projectsSlice";
import productionStagesReducer from "@/app/lib/store/slices/productionStages/productionStagesSlice";
import qualityChecksReducer from "@/app/lib/store/slices/qualityChecks/qualityChecksSlice";
import customersReducer from "@/app/lib/store/slices/customers/customersSlice";
import manufacturingFacilitiesReducer from "@/app/lib/store/slices/manufacturingFacilities/manufacturingFacilitiesSlice";
import appReducer from "@/app/lib/store/slices/appSlice";
import companyInformationReducer from "@/app/lib/store/slices/companyInformation/companyInformationSlice";
import usedSerialNumbersReducer from "@/app/lib/store/slices/usedSerialNumbers/usedSerialNumbersSlice";
import companyUsersReducer from "@/app/lib/store/slices/companyUsers/companyUsersSlice";
import batchesReducer from "@/app/lib/store/slices/batches/batchesSlice";
import reservedSerialNumbersReducer from "@/app/lib/store/slices/reservedSerialNumbers/reservedSerialNumbersSlice";
import checklistsReducer from "@/app/lib/store/slices/checklists/checklistsSlice";
import suppliersReducer from "@/app/lib/store/slices/suppliers/suppliersSlice";
import ordersReducer from "@/app/lib/store/slices/orders/ordersSlice";
import prodStagesTimeManagementReducer from "@/app/lib/store/slices/productionStages/prodStagesTimeManagementSlice";
import tasksReducer from "@/app/lib/store/slices/tasks/tasksSlice";
import paperQualityChecksReducer from "@/app/lib/store/slices/paperQualityChecks/paperQualityChecksSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    companyInformation: companyInformationReducer,
    projects: projectsReducer,
    productionStages: productionStagesReducer,
    prodStagesTimeManagement: prodStagesTimeManagementReducer,
    orders: ordersReducer,
    checklists: checklistsReducer,
    qualityChecks: qualityChecksReducer,
    customers: customersReducer,
    manufacturingFacilities: manufacturingFacilitiesReducer,
    usedSerialNumbers: usedSerialNumbersReducer,
    companyUsers: companyUsersReducer,
    batches: batchesReducer,
    reservedSerialNumbers: reservedSerialNumbersReducer,
    suppliers: suppliersReducer,
    tasks: tasksReducer,
    paperQualityChecks: paperQualityChecksReducer,
  }, // DON'T FORGET TO ADD CLEANUP FUNCTIONS TO APP SLICE AND CALL IT AT appSlice.ts
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
