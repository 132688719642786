import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";

// Base selector to get the checklists state
const selectChecklistsState = (state: RootState) => state.checklists;

/**
 * Selector to get checklist items for a specific production stage.
 * @param productionStageId - The ID of the production stage to get checklist items for. If null, returns empty array.
 * @returns A selector function that returns an array of ChecklistItem objects for the given production stage ID.
 * If no items exist for that stage, returns empty array.
 */

export const selectChecklistItems = (productionStageId?: string | null) =>
  createSelector(selectChecklistsState, (checklistsState) =>
    productionStageId ? checklistsState.items[productionStageId] || [] : [],
  );

// Select loading state for a specific production stage's checklist
export const selectChecklistLoading = (productionStageId: string) =>
  createSelector(
    selectChecklistsState,
    (checklistsState) => checklistsState.isLoading[productionStageId] || false,
  );

// Select error state for a specific production stage's checklist
export const selectChecklistError = (productionStageId: string) =>
  createSelector(
    selectChecklistsState,
    (checklistsState) => checklistsState.error[productionStageId] || null,
  );

// Select a specific checklist item by its ID
export const selectChecklistItemById = (
  productionStageId: string,
  itemId: string,
) =>
  createSelector(selectChecklistItems(productionStageId), (items) =>
    items.find((item) => item.id === itemId),
  );

// Select checklist items sorted by order
export const selectSortedChecklistItems = (productionStageId: string) =>
  createSelector(selectChecklistItems(productionStageId), (items) =>
    [...items].sort((a, b) => (a.order || 0) - (b.order || 0)),
  );

// Select required checklist items
export const selectRequiredChecklistItems = (productionStageId: string) =>
  createSelector(selectChecklistItems(productionStageId), (items) =>
    items.filter((item) => item.required),
  );

// Select checklist items with reference images
export const selectChecklistItemsWithImages = (productionStageId: string) =>
  createSelector(selectChecklistItems(productionStageId), (items) =>
    items.filter(
      (item) => item.referenceImageUrls && item.referenceImageUrls.length > 0,
    ),
  );

// Select checklist items with PDF references
export const selectChecklistItemsWithPDFs = (productionStageId: string) =>
  createSelector(selectChecklistItems(productionStageId), (items) =>
    items.filter((item) => item.pdfReferences && item.pdfReferences.length > 0),
  );

// Select the total count of checklist items
export const selectChecklistItemsCount = (productionStageId: string) =>
  createSelector(
    selectChecklistItems(productionStageId),
    (items) => items.length,
  );

// Select checklist metadata
export const selectChecklistMetadata = (productionStageId: string) =>
  createSelector(selectChecklistItems(productionStageId), (items) => ({
    totalItems: items.length,
    requiredItems: items.filter((item) => item.required).length,
    itemsWithImages: items.filter(
      (item) => item.referenceImageUrls && item.referenceImageUrls.length > 0,
    ).length,
    itemsWithPDFs: items.filter((item) => item.pdfReferences && item.pdfReferences.length > 0).length,
  }));
