import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CompanyReservedSerialNumberRange } from "@/app/lib/interfaces";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";
import { RootState } from "../../store";
import { useDocument } from "@/app/lib/firebase/firebaseSubscriptions";
import { DocumentSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: DocumentSubscription<{ reservedRanges: CompanyReservedSerialNumberRange[] }> | null } = {};

interface ReservedSerialNumbersState {
  ranges: CompanyReservedSerialNumberRange[];
  loading: boolean;
  error: string | null;
}

const initialState: ReservedSerialNumbersState = {
  ranges: [],
  loading: false,
  error: null,
};

export const initializeReservedSerialNumbers = createAsyncThunk(
  "reservedSerialNumbers/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return;
    }

    try {
      // Create subscription with 7-day caching
      const subscription = await useDocument<{ reservedRanges: CompanyReservedSerialNumberRange[] }>(
        PATHS.documents.reservedSerialNumbers(companyId),
        (data) => {
          if (data) {
            dispatch(setReservedSerialNumbers(data.reservedRanges || []));
          } else {
            dispatch(setReservedSerialNumbers([]));
          }
        },
        (error) => {
          console.error("Error fetching reserved serial numbers:", error);
          dispatch(setReservedSerialNumbers([]));
        },
        {
          // better to not cache, as this creates risk of using wrong serial number
          useCache: false,
          // cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setReservedSerialNumbers([]));
    }
  }
);

export const cleanupReservedSerialNumbers = createAsyncThunk(
  "reservedSerialNumbers/cleanup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (companyId && subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }
  }
);

export const cleanupAllReservedSerialNumbers = createAsyncThunk(
  "reservedSerialNumbers/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

const reservedSerialNumbersSlice = createSlice({
  name: "reservedSerialNumbers",
  initialState,
  reducers: {
    setReservedSerialNumbers: (
      state,
      action: PayloadAction<CompanyReservedSerialNumberRange[]>,
    ) => {
      state.ranges = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize reserved serial numbers
      .addCase(initializeReservedSerialNumbers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initializeReservedSerialNumbers.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(initializeReservedSerialNumbers.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.loading = false;
      })
      // Cleanup
      .addCase(cleanupReservedSerialNumbers.fulfilled, (state) => {
        state.ranges = [];
        state.loading = false;
        state.error = null;
      })
      .addCase(cleanupAllReservedSerialNumbers.fulfilled, (state) => {
        state.ranges = [];
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setReservedSerialNumbers } = reservedSerialNumbersSlice.actions;

export default reservedSerialNumbersSlice.reducer;
