import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

// Base selector
const selectProductionStagesState = (state: RootState) =>
  state.productionStages;

export const selectAllProductionStagesArray = createSelector(
  selectProductionStagesState,
  (state) => Object.values(state.items).flat(),
);

// Select all production stages for a specific project
export const selectProjectProductionStages = (projectId: string) =>
  createSelector(
    selectProductionStagesState,
    (state) => state.items[projectId] || [],
  );

export const selectProjectProductionStageById = (
  projectId: string,
  productionStageId: string,
) =>
  createSelector(
    selectProjectProductionStages(projectId),
    (productionStages) =>
      productionStages.find(
        (productionStage) => productionStage.id === productionStageId,
      ) || null,
  );

// Select a specific production stage by ID
export const selectProductionStage = (
  projectId: string,
  productionStageId: string,
) =>
  createSelector(
    selectProjectProductionStages(projectId),
    (productionStages) =>
      productionStages.find(
        (productionStage) => productionStage.id === productionStageId,
      ) || null,
  );

// Select loading state for a specific project
export const selectProductionStagesLoading = (projectId: string) =>
  createSelector(
    selectProductionStagesState,
    (state) => state.isLoading[projectId] || false,
  );

// Select error state for a specific project
export const selectProductionStagesError = (projectId: string) =>
  createSelector(
    selectProductionStagesState,
    (state) => state.error[projectId] || null,
  );
