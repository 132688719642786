import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";

// Basic selectors
export const selectSuppliersState = (state: RootState) => state.suppliers;
export const selectAllSuppliers = (state: RootState) => state.suppliers.suppliersList;
export const selectSuppliersLoading = (state: RootState) => state.suppliers.loading;
export const selectSuppliersError = (state: RootState) => state.suppliers.error;
export const selectLastTimeFetched = (state: RootState) => state.suppliers.lastTimeFetched;
export const selectSuppliersSearchResults = (state: RootState) => state.suppliers.searchResults;

export const selectSupplierById = (supplierId?: string | null) =>
    createSelector(selectAllSuppliers, (suppliers) =>
        supplierId ? suppliers.find((supplier) => supplier.id === supplierId) : null,
    );

export const selectSuppliersByProduct = (productName: string) =>
    createSelector(selectAllSuppliers, (suppliers) =>
        suppliers.filter((supplier) => supplier.products.includes(productName))
    );

export const selectMainSupplierContacts = () =>
    createSelector(selectAllSuppliers, (suppliers) =>
        suppliers.map(supplier => ({
            supplierId: supplier.id,
            supplierName: supplier.name,
            mainContact: supplier.contacts.find(contact => contact.isMain) || supplier.contacts[0]
        }))
    );

export const selectSuppliersWithMainAddress = () =>
    createSelector(selectAllSuppliers, (suppliers) =>
        suppliers.map(supplier => ({
            ...supplier,
            mainAddress: supplier.addresses.find(address => address.isMain) || supplier.addresses[0]
        }))
    );

// Utility selector for sorted suppliers
export const selectSortedSuppliers = () =>
    createSelector(selectAllSuppliers, (suppliers) =>
        [...suppliers].sort((a, b) => a.name.localeCompare(b.name))
    );

// Selector for recently added suppliers (last 30 days)
export const selectRecentlyAddedSuppliers = () =>
    createSelector(selectAllSuppliers, (suppliers) => {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        return suppliers.filter(supplier =>
            new Date(supplier.addedAt) > thirtyDaysAgo
        );
    }
    );

// Selector for suppliers with complete information
export const selectSuppliersWithCompleteInfo = () =>
    createSelector(selectAllSuppliers, (suppliers) => suppliers.filter(supplier =>
        supplier.name &&
        supplier.description &&
        supplier.products.length > 0 &&
        supplier.addresses.length > 0 &&
        supplier.contacts.length > 0
    )
    );

// Selector for suppliers statistics
export const selectSuppliersStats = () =>
    createSelector(selectAllSuppliers, (suppliers) => ({
        total: suppliers.length,
        withProducts: suppliers.filter(s => s.products.length > 0).length,
        withMultipleAddresses: suppliers.filter(s => s.addresses.length > 1).length,
        withMultipleContacts: suppliers.filter(s => s.contacts.length > 1).length,
    })
    );
