import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { TimeManagementForProdStage, ProductionStagesTimeManagement } from "@/app/lib/interfaces";

const selectProdStagesTimeManagementState = (state: RootState) => {
    return state.prodStagesTimeManagement
};

export const selectAllProdStagesTimeManagement = (state: RootState) => createSelector(
    selectProdStagesTimeManagementState,
    (state) => state.items
);

export const selectAllProdStagesTimeManagementArray = createSelector(
    [(state: RootState) => state.prodStagesTimeManagement.items],
    (items): ProductionStagesTimeManagement[] => {
        const result: ProductionStagesTimeManagement[] = [];

        // Iterate through projects
        Object.keys(items).forEach(projectId => {
            // Iterate through orders in each project
            Object.keys(items[projectId] || {}).forEach(orderId => {
                if (items[projectId][orderId]) {
                    result.push(items[projectId][orderId]);
                }
            });
        });

        return result;
    }
);

export const selectProjectProdStagesTimeManagement = (projectId: string) => createSelector(
    selectProdStagesTimeManagementState,
    (state) => state.items[projectId] || {},
);

export const selectProdStagesTimeManagementForOrder = (projectId: string, orderId: string) => createSelector(
    selectProjectProdStagesTimeManagement(projectId),
    (prodStagesTimeManagement) => prodStagesTimeManagement[orderId] || null,
);

export const selectProdStageTimeManagement = (projectId: string, prodStageId: string) => createSelector( // orderId -> timeManagement for prodStageId
    selectProjectProdStagesTimeManagement(projectId),
    (prodStagesForOrder) => {
        console.log("[selectProdStageTimeManagement] prodStagesForOrder", prodStagesForOrder);
        const result: Record<string, TimeManagementForProdStage> = {};
        Object.keys(prodStagesForOrder).forEach((orderId) => {
            const prodStages = prodStagesForOrder[orderId];
            const prodStageWeNeed = prodStages.timeManagement?.[prodStageId];
            if (prodStageWeNeed) {
                result[orderId] = prodStageWeNeed;
            }
        })
        return result;
    }
);


