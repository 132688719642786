import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { SupplierReference } from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import { subscribeToSupplierById, cleanupSupplierSubscription } from "@/app/lib/store/slices/suppliers/suppliersSlice";
import { Supplier } from "@/app/lib/suppliersDBInterfaces";
import { setDocumentWithHistory, softDeleteDocumentWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { removeUndefined } from "@/app/lib/firebase/firebaseFunctions";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<SupplierReference> | null } = {};

export interface ManufacturingFacilitiesState {
  references: { [key: string]: SupplierReference };
  suppliers: { [key: string]: Supplier };
  isLoading: boolean;
  error: string | null;
}

const initialState: ManufacturingFacilitiesState = {
  references: {},
  suppliers: {},
  isLoading: false,
  error: null,
};

export const addSupplierReference = createAsyncThunk(
  "manufacturingFacilities/addReference",
  async (
    { newSupplierReference }: { newSupplierReference: Omit<SupplierReference, "id"> },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.collections.manufacturingFacilities(companyId);
    const newRefWithoutUndefined = removeUndefined(newSupplierReference);

    const newReference = await setDocumentWithHistory<SupplierReference>({
      path,
      data: newRefWithoutUndefined,
      userId
    });

    // Initialize supplier subscription
    await dispatch(subscribeToSupplierById(newSupplierReference.supplierId));

    return newReference;
  }
);

export const deleteSupplierReference = createAsyncThunk(
  "manufacturingFacilities/deleteReference",
  async (
    { supplierId }: { supplierId: string },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.manufacturingFacility(companyId, supplierId);
    await softDeleteDocumentWithHistory({
      path,
      userId,
    });

    // Cleanup supplier subscription
    await dispatch(cleanupSupplierSubscription(supplierId));

    return { supplierId };
  }
);

export const initializeManufacturingFacilities = createAsyncThunk(
  "manufacturingFacilities/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return;
    }

    const collectionPath = PATHS.collections.manufacturingFacilities(companyId);
    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
    ];

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<SupplierReference>(
        collectionPath,
        (references) => {
          dispatch(setManufacturingFacilitiesReferences(references));
          // Initialize supplier subscriptions
          references.forEach(reference => {
            dispatch(subscribeToSupplierById(reference.supplierId));
          });
        },
        (error) => {
          console.error("Error fetching manufacturing facilities:", error);
          dispatch(setManufacturingFacilitiesReferences([]));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setManufacturingFacilitiesReferences([]));
    }
  }
);

export const cleanupManufacturingFacilities = createAsyncThunk(
  "manufacturingFacilities/cleanup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (companyId && subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }

    // Cleanup all supplier subscriptions
    const supplierIds = Object.values(state.manufacturingFacilities.references).map(ref => ref.id);
    await Promise.all(supplierIds.map(id => dispatch(cleanupSupplierSubscription(id))));
  }
);

export const cleanupAllManufacturingFacilities = createAsyncThunk(
  "manufacturingFacilities/cleanupAll",
  async (_, { dispatch, getState }) => {
    // Cleanup all facility subscriptions
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};

    // Cleanup all supplier subscriptions
    const state = getState() as RootState;
    const supplierIds = Object.values(state.manufacturingFacilities.references).map(ref => ref.id);
    await Promise.all(supplierIds.map(id => dispatch(cleanupSupplierSubscription(id))));
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

const manufacturingFacilitiesSlice = createSlice({
  name: "manufacturingFacilities",
  initialState,
  reducers: {
    setManufacturingFacilitiesReferences: (
      state,
      action: PayloadAction<SupplierReference[]>
    ) => {
      const newReferences: { [key: string]: SupplierReference } = {};
      action.payload.forEach((facility) => {
        newReferences[facility.id] = facility;
      });
      state.references = newReferences;
      state.error = null;
    },
    removeManufacturingFacility: (state, action: PayloadAction<string>) => {
      delete state.references[action.payload];
      delete state.suppliers[action.payload];
    },
    updateSupplier: (state, action: PayloadAction<Supplier>) => {
      state.suppliers[action.payload.id] = action.payload;
      if (state.references[action.payload.id]) {
        state.references[action.payload.id] = {
          ...state.references[action.payload.id],
          name: action.payload.name
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize facilities
      .addCase(initializeManufacturingFacilities.pending, (state) => {
        state.references = {};
        state.suppliers = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeManufacturingFacilities.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(initializeManufacturingFacilities.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Add reference
      .addCase(addSupplierReference.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addSupplierReference.fulfilled, (state, action) => {
        state.references[action.payload.id] = action.payload;
        state.isLoading = false;
      })
      .addCase(addSupplierReference.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Delete reference
      .addCase(deleteSupplierReference.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSupplierReference.fulfilled, (state, action) => {
        delete state.references[action.payload.supplierId];
        delete state.suppliers[action.payload.supplierId];
        state.isLoading = false;
      })
      .addCase(deleteSupplierReference.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Cleanup
      .addCase(cleanupManufacturingFacilities.fulfilled, (state) => {
        state.references = {};
        state.suppliers = {};
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { setManufacturingFacilitiesReferences, removeManufacturingFacility, updateSupplier } =
  manufacturingFacilitiesSlice.actions;

export default manufacturingFacilitiesSlice.reducer;
