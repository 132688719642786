import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CompanyUser } from "../../../interfaces";
import { PATHS } from "../../../firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";
import { RootState } from "../../store";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<CompanyUser> | null } = {};

export interface CompanyUsersState {
  items: { [key: string]: CompanyUser };
  isLoading: boolean;
  error: string | null;
}

const initialState: CompanyUsersState = {
  items: {},
  isLoading: false,
  error: null,
};

export const initializeCompanyUsers = createAsyncThunk(
  "companyUsers/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return;
    }

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<CompanyUser>(
        PATHS.collections.companyUsers(companyId),
        (users) => {
          dispatch(setCompanyUsers(users));
        },
        (error) => {
          console.error("Error fetching company users:", error);
          dispatch(setCompanyUsers([]));
        },
        undefined,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setCompanyUsers([]));
    }
  }
);

export const cleanupCompanyUsers = createAsyncThunk(
  "companyUsers/cleanup",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (companyId && subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }
  }
);

export const cleanupAllCompanyUsers = createAsyncThunk(
  "companyUsers/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState,
  reducers: {
    setCompanyUsers: (state, action: PayloadAction<CompanyUser[]>) => {
      // Convert array to object while preserving existing items
      action.payload.forEach((user) => {
        state.items[user.userId] = user;
      });
      state.error = null;
    },
    updateCompanyUser: (state, action: PayloadAction<CompanyUser>) => {
      state.items[action.payload.userId] = action.payload;
    },
    removeCompanyUser: (state, action: PayloadAction<string>) => {
      delete state.items[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize company users
      .addCase(initializeCompanyUsers.pending, (state) => {
        state.items = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeCompanyUsers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(initializeCompanyUsers.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Cleanup
      .addCase(cleanupCompanyUsers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(cleanupAllCompanyUsers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { setCompanyUsers, updateCompanyUser, removeCompanyUser } =
  companyUsersSlice.actions;

export default companyUsersSlice.reducer;
