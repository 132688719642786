import { ProductionStagesTimeManagement } from "@/app/lib/interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";
import { setDocumentWithHistory, softDeleteDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { selectProdStagesTimeManagementForOrder } from "./prodStagesTimeManagementSelectors";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [projectId: string]: CollectionSubscription<ProductionStagesTimeManagement> | null } = {};

export interface ProdStagesTimeManagementState {
    items: {
        [projectId: string]: {
            [orderId: string]: ProductionStagesTimeManagement;
        };
    };
    isLoading: {
        [projectId: string]: boolean;
    };
    error: {
        [projectId: string]: string | null;
    };
}

const initialState: ProdStagesTimeManagementState = {
    items: {},
    isLoading: {},
    error: {},
};

export const initializeProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/initialize",
    async (projectId: string, { dispatch, getState }) => {
        const state = getState() as RootState;

        const { companyId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId) {
            throw new Error("No company selected");
        }

        // Check if we already have an active subscription for this project
        if (subscriptions[projectId]) {
            return state.prodStagesTimeManagement.items[projectId] || {};
        }

        const collectionPath = PATHS.collections.prodStageTimeManagement(companyId, projectId);

        try {
            // Create subscription with 7-day caching
            const subscription = await useCollection<ProductionStagesTimeManagement>(
                collectionPath,
                (prodStagesTimeManagement) => {
                    dispatch(setProdStagesTimeManagement({ projectId, prodStagesTimeManagement }));
                },
                (error) => {
                    console.error("Error fetching production stages time management:", error);
                    dispatch(setProdStagesTimeManagement({ projectId, prodStagesTimeManagement: [] }));
                },
                undefined,
                {
                    useCache: true,
                    cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
                }
            );

            // Store the subscription for cleanup later
            subscriptions[projectId] = subscription;
        } catch (error) {
            console.error("Error creating subscription:", error);
            dispatch(setProdStagesTimeManagement({ projectId, prodStagesTimeManagement: [] }));
        }

        return state.prodStagesTimeManagement.items[projectId] || {};
    },
);

export const cleanupProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/cleanup",
    async (projectId: string) => {
        if (subscriptions[projectId]) {
            subscriptions[projectId]!.unsubscribe();
            subscriptions[projectId] = null;
        }
    },
);

export const cleanupAllProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/cleanupAll",
    async () => {
        Object.keys(subscriptions).forEach((projectId) => {
            if (subscriptions[projectId]) {
                subscriptions[projectId]!.unsubscribe();
                subscriptions[projectId] = null;
            }
        });
        subscriptions = {};
    },
);

export const hasActiveSubscription = (projectId: string) => {
    return !!subscriptions[projectId];
};

export const addProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/add",
    async (
        { projectId, prodStagesTimeManagement }:
            { prodStagesTimeManagement: Omit<ProductionStagesTimeManagement, "id">, projectId: string },
        { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const prodStagesTimeManagementPath = PATHS.collections.prodStageTimeManagement(companyId, projectId);

        const newProdStagesTimeManagement = await setDocumentWithHistory<ProductionStagesTimeManagement>({
            path: prodStagesTimeManagementPath,
            data: prodStagesTimeManagement,
            userId
        });

        return { projectId, prodStagesTimeManagement: newProdStagesTimeManagement };
    },
);

export const updateProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/update",
    async (
        { projectId, prodStagesTimeManagement }:
            { prodStagesTimeManagement: ProductionStagesTimeManagement, projectId: string },
        { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const prodStagesTimeManagementPath = PATHS.documents.prodStageTimeManagement(companyId, projectId, prodStagesTimeManagement.id);

        const originalProdStagesTimeManagement = selectProdStagesTimeManagementForOrder(projectId, prodStagesTimeManagement.orderId)(state);

        if (!originalProdStagesTimeManagement) {
            throw new Error("ProdStagesTimeManagement not found");
        }

        await updateDocumentsWithHistory([{
            path: prodStagesTimeManagementPath,
            oldData: originalProdStagesTimeManagement,
            newData: prodStagesTimeManagement,
            userId
        }]);

        return { projectId, prodStagesTimeManagement };
    },
);

export const softDeleteProdStagesTimeManagement = createAsyncThunk(
    "prodStagesTimeManagement/softDelete",
    async ({ projectId, orderId }: { projectId: string, orderId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const prodStagesTimeManagementPath = PATHS.documents.prodStageTimeManagement(companyId, projectId, orderId);

        await softDeleteDocumentWithHistory({
            path: prodStagesTimeManagementPath,
            userId
        });
    },
);

const prodStagesTimeManagementSlice = createSlice({
    name: "prodStagesTimeManagement",
    initialState,
    reducers: {
        setProdStagesTimeManagement: (state, action: PayloadAction<{
            projectId: string;
            prodStagesTimeManagement: ProductionStagesTimeManagement[];
        }>) => {
            const { projectId, prodStagesTimeManagement } = action.payload;
            state.items[projectId] = prodStagesTimeManagement.reduce((acc, curr) => {
                acc[curr.orderId] = curr;
                return acc;
            }, {} as { [orderId: string]: ProductionStagesTimeManagement });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeProdStagesTimeManagement.pending, (state, action) => {
            const projectId = action.meta.arg;
            if (!state.items[projectId]) {
                state.items[projectId] = {};
            }
            state.isLoading[projectId] = true;
            state.error[projectId] = null;
        })
            .addCase(initializeProdStagesTimeManagement.fulfilled, (state, action) => {
                const projectId = action.meta.arg;
                state.isLoading[projectId] = false;
            })
            .addCase(initializeProdStagesTimeManagement.rejected, (state, action) => {
                const projectId = action.meta.arg;
                state.error[projectId] = action.error.message || "Unknown error";
                state.isLoading[projectId] = false;
            })
            .addCase(cleanupProdStagesTimeManagement.fulfilled, (state, action) => {
                const projectId = action.meta.arg;
                delete state.items[projectId];
                delete state.isLoading[projectId];
                delete state.error[projectId];
            })
            .addCase(cleanupAllProdStagesTimeManagement.fulfilled, (state) => {
                state.items = {};
                state.isLoading = {};
                state.error = {};
            });
    },
});

export const { setProdStagesTimeManagement } = prodStagesTimeManagementSlice.actions;

export default prodStagesTimeManagementSlice.reducer;



