import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";
import { Project, AllowedQualityCheckUserDetails } from "@/app/lib/interfaces";

// Base selector
const selectProjectsState = (state: RootState) => state.projects;

// Select all projects as an object
export const selectProjectsItems = createSelector(
  selectProjectsState,
  (state) => state.items,
);

export const selectClosedProjectsItems = createSelector(
  selectProjectsState,
  (state) => state.closedItems,
);

export const selectProjectName = (projectId: string) =>
  createSelector(
    selectProjectsItems,
    (items) => items[projectId]?.name || null,
  );

// Select all projects as an array
export const selectProjectsArray = createSelector(
  selectProjectsItems,
  (items) => Object.values(items),
);

// Select loading state
export const selectProjectsLoading = createSelector(
  selectProjectsState,
  (state) => state.isLoading,
);

// Select error state
export const selectProjectsError = createSelector(
  selectProjectsState,
  (state) => state.error,
);

// Select a single project by ID
export const selectProjectById = (projectId: string): (state: RootState) => Project | null =>
  createSelector(
    selectProjectsItems,
    selectClosedProjectsItems,
    (items, closedItems) => {
      const project = Object.values(items).find(pr => pr.id === projectId);
      if (project) {
        return project;
      }

      if (closedItems[projectId]) {
        return closedItems[projectId];
      }

      return null;
    }
  );

export const selectProjectAllowedQualityCheckUsers = (projectId: string) =>
  createSelector(
    selectProjectById(projectId),
    (project): Record<string, AllowedQualityCheckUserDetails[]> | null =>
      project?.allowedQualityCheckUsersPerProductionStage || null,
  );