import { Order } from "@/app/lib/interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";
import { PATHS } from "@/app/lib/firebase/constants";
import { OrderByDirection, WhereFilterOp } from "firebase/firestore";
import { fetchDocument, setDocumentWithHistory, softDeleteDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { AtLeast } from "@/app/lib/types/global";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [projectId: string]: CollectionSubscription<Order> | null } = {};

export interface OrdersState {
    items: {
        [projectId: string]: Order[];
    }
    isLoading: {
        [projectId: string]: boolean;
    }
    error: {
        [projectId: string]: string | null;
    }
}

const initialState: OrdersState = {
    items: {},
    isLoading: {},
    error: {},
}

export const initializeOrders = createAsyncThunk(
    'orders/initialize', async (projectId: string, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        // Check if we already have an active subscription for this project
        if (subscriptions[projectId]) {
            return state.orders.items[projectId] || [];
        }

        const collectionPath = PATHS.collections.orders(companyId, projectId);
        const queryConstraints = [
            { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
            { field: "createdAt", direction: "desc" as OrderByDirection, type: "orderBy" as const }
        ];

        try {
            // Create subscription with 7-day caching
            const subscription = await useCollection<Order>(
                collectionPath,
                (orders) => {
                    dispatch(setOrdersInState({ projectId, orders }));
                },
                (error) => {
                    console.error("Error fetching orders:", error);
                    dispatch(setOrdersInState({ projectId, orders: [] }));
                },
                queryConstraints,
                {
                    useCache: true,
                    cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
                }
            );

            // Store the subscription for cleanup later
            subscriptions[projectId] = subscription;
        } catch (error) {
            console.error("Error creating subscription:", error);
            dispatch(setOrdersInState({ projectId, orders: [] }));
        }

        return state.orders.items[projectId] || [];
    })

export const cleanupOrders = createAsyncThunk(
    "orders/cleanup",
    async (projectId: string) => {
        if (subscriptions[projectId]) {
            subscriptions[projectId]!.unsubscribe();
            subscriptions[projectId] = null;
        }
    },
);

export const cleanupAllOrders = createAsyncThunk(
    "orders/cleanupAll",
    async () => {
        Object.keys(subscriptions).forEach((projectId) => {
            if (subscriptions[projectId]) {
                subscriptions[projectId]!.unsubscribe();
                subscriptions[projectId] = null;
            }
        });
        subscriptions = {};
    },
);

export const hasActiveSubscription = (projectId: string) => {
    return !!subscriptions[projectId];
};

export const addOrder = createAsyncThunk(
    "orders/add",
    async ({ order, projectId }: { order: Order, projectId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const path = PATHS.collections.orders(companyId, projectId);
        const newOrder = await setDocumentWithHistory<Order>({
            path,
            data: order,
            userId,
        });

        return newOrder;
    }
)

export const updateOrder = createAsyncThunk(
    "orders/update",
    async ({ order, projectId }: { order: AtLeast<Order, "id">, projectId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const existingOrder = state.orders.items[projectId].find(o => o.id === order.id);

        if (!existingOrder) {
            throw new Error("Order not found");
        }

        const orderToupdate: Order = {
            ...existingOrder,
            ...order,
        }

        const path = PATHS.documents.order(companyId, projectId, order.id);
        const updatedOrder = await updateDocumentsWithHistory<Order>([{
            path,
            oldData: existingOrder,
            newData: orderToupdate,
            userId,
        }]);

        return updatedOrder;
    }
)

export const softDeleteOrder = createAsyncThunk(
    "orders/softDelete",
    async ({ orderId, projectId }: { orderId: string, projectId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }

        const path = PATHS.documents.order(companyId, projectId, orderId);
        const updatedOrder = await softDeleteDocumentWithHistory<Order>({
            path,
            userId,
        });

        return updatedOrder;
    }
)

export const fetchOrder = createAsyncThunk(
    "orders/fetch",
    async ({ projectId, orderId }: { projectId: string, orderId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId) {
            throw new Error("No company selected");
        }

        const storeOrder = state.orders.items[projectId]?.find(o => o.id === orderId);
        if (storeOrder) {
            return storeOrder;
        }

        const path = PATHS.documents.order(companyId, projectId, orderId);
        const order = await fetchDocument<Order>(path);
        return order;
    }
)

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrdersInState: (state, action: PayloadAction<{ projectId: string, orders: Order[] }>) => {
            state.items[action.payload.projectId] = action.payload.orders;
        },
        updateOrderState: (state, action: PayloadAction<Order>) => {
            state.items[action.payload.projectId] = state.items[action.payload.projectId].map(order => order.id === action.payload.id ? action.payload : order);
        },
        removeOrderFromState: (state, action: PayloadAction<{ projectId: string, orderId: string }>) => {
            state.items[action.payload.projectId] = state.items[action.payload.projectId].filter(order => order.id !== action.payload.orderId);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(initializeOrders.pending, (state, action) => {
                const projectId = action.meta.arg;
                state.isLoading[projectId] = true;
                state.error[projectId] = null;
            })
            .addCase(initializeOrders.fulfilled, (state, action) => {
                const projectId = action.meta.arg;
                state.isLoading[projectId] = false;
            })
            .addCase(initializeOrders.rejected, (state, action) => {
                const projectId = action.meta.arg;
                state.error[projectId] = action.error.message || "Unknown error";
                state.isLoading[projectId] = false;
            })
            .addCase(cleanupOrders.fulfilled, (state, action) => {
                const projectId = action.meta.arg;
                delete state.items[projectId];
                delete state.isLoading[projectId];
                delete state.error[projectId];
            })
            .addCase(cleanupAllOrders.fulfilled, (state) => {
                state.items = {};
                state.isLoading = {};
                state.error = {};
            });
    },
})

export const { setOrdersInState, updateOrderState, removeOrderFromState } = ordersSlice.actions;

export default ordersSlice.reducer;
