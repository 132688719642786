import { Task, TaskMentionType, UserRoleLevels } from "@/app/lib/interfaces";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { selectSelectedCompanyIdAndUserId, selectUserInformation } from "../appSelectors";
import { PATHS } from "@/app/lib/firebase/constants";
import { OrderByDirection, WhereFilterOp } from "firebase/firestore";
import { setDocumentWithHistory, softDeleteDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { selectTaskById } from "./tasksSelector";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<Task> | null } = {};

export interface TasksState {
    items: {
        [companyId: string]: Record<string, Task>;
    };
    loading: {
        [companyId: string]: boolean;
    };
    error: {
        [companyId: string]: string | null;
    };
}

const initialState: TasksState = {
    items: {},
    loading: {},
    error: {},
}

export const initializeTasks = createAsyncThunk(
    "tasks/initialize",
    async (_, { dispatch, getState }) => {
        const state = getState() as RootState;
        const { companyId } = selectSelectedCompanyIdAndUserId(state);
        if (!companyId) {
            throw new Error("No company id found");
        }
        dispatch(initializeTasksForCompany(companyId));
    }
)

const initializeTasksForCompany = createAsyncThunk(
    "tasks/initializeTasks",
    async (companyId: string, { dispatch, getState }) => {
        const state = getState() as RootState;

        const user = selectUserInformation(state);

        if (!user || !companyId) {
            throw new Error("No user or company id found");
        }

        const userRole = user.companies.find((company) => company.companyId === companyId)?.userRole;

        if (!userRole || userRole === UserRoleLevels.NOT_AUTHORIZED) {
            throw new Error("User role not found");
        }

        // Check if we already have an active subscription for this company
        if (subscriptions[companyId]) {
            return;
        }

        const collectionPath = PATHS.collections.tasks(companyId);

        const queryConstraints = [
            {
                field: "isSoftDeleted",
                operator: "==" as WhereFilterOp,
                value: false,
            },
            {
                field: "createdAt",
                direction: "desc" as OrderByDirection,
                type: "orderBy" as const
            }
        ];

        try {
            // Create subscription without caching (as specified)
            const subscription = await useCollection<Task>(
                collectionPath,
                (tasks) => {
                    if (userRole !== UserRoleLevels.ADMIN && userRole !== UserRoleLevels.MANAGER) {
                        tasks = tasks.filter((task) => {
                            if (task.createdBy === user.id) {
                                return true;
                            }
                            if (task.ownerId === user.id) {
                                return true;
                            }
                            if (task.assignedTo?.includes(user.id)) {
                                return true;
                            }
                            if (task.mentions?.some((mention) => mention.type === TaskMentionType.USER && mention.mentionedId === user.id)) {
                                return true;
                            }
                            return false;
                        });
                    }
                    dispatch(setTasks({ companyId, tasks }));
                },
                (error) => {
                    console.error("Error fetching tasks:", error);
                    dispatch(setTasks({ companyId, tasks: [] }));
                },
                queryConstraints,
                {
                    useCache: false // Explicitly disable caching
                }
            );

            // Store the subscription for cleanup later
            subscriptions[companyId] = subscription;
        } catch (error) {
            console.error("Error creating subscription:", error);
            dispatch(setTasks({ companyId, tasks: [] }));
        }
    }
)

export const cleanupTasks = createAsyncThunk(
    "tasks/cleanup",
    async (companyId: string) => {
        if (subscriptions[companyId]) {
            subscriptions[companyId]!.unsubscribe();
            subscriptions[companyId] = null;
        }
    }
)

export const cleanupAllTasks = createAsyncThunk(
    "tasks/cleanupAll",
    async () => {
        Object.keys(subscriptions).forEach((companyId) => {
            if (subscriptions[companyId]) {
                subscriptions[companyId]!.unsubscribe();
                subscriptions[companyId] = null;
            }
        });
        subscriptions = {};
    }
)

export const hasActiveSubscription = (companyId: string) => {
    return !!subscriptions[companyId];
}

export const addTask = createAsyncThunk(
    "tasks/add",
    async ({ task }: { task: Omit<Task, "id"> }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }
        const path = PATHS.collections.tasks(companyId);
        await setDocumentWithHistory<Task>({ path, data: task, userId });
    }
)

export const updateTask = createAsyncThunk(
    "tasks/update",
    async ({ task, taskId }: { task: Partial<Task>; taskId: string }, { getState }) => { // TODO: Change to AtLeast
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }
        const oldTask = selectTaskById(taskId)(state);
        if (!oldTask) {
            throw new Error("Task not found");
        }
        const newTask = { ...oldTask, ...task };
        const path = PATHS.documents.task(companyId, taskId);
        await updateDocumentsWithHistory<Task>([{ path, oldData: oldTask, newData: newTask, userId }]);
    }
)

export const softDeleteTask = createAsyncThunk(
    "tasks/delete",
    async ({ taskId }: { taskId: string }, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected");
        }
        const path = PATHS.documents.task(companyId, taskId);
        await softDeleteDocumentWithHistory<Task>({ path, userId });
    }
)

export const tasksSlice = createSlice({
    name: "tasks",
    initialState,
    reducers: {
        setTasks: (state, action: PayloadAction<{ companyId: string; tasks: Task[] }>) => {
            const { companyId, tasks } = action.payload;
            state.items[companyId] = tasks.reduce((acc, task) => {
                acc[task.id] = task;
                return acc;
            }, {} as Record<string, Task>);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeTasksForCompany.pending, (state, action) => {
            const companyId = action.meta.arg;
            state.loading[companyId] = true;
            state.error[companyId] = null;
        })
        builder.addCase(initializeTasksForCompany.fulfilled, (state, action) => {
            const companyId = action.meta.arg;
            state.loading[companyId] = false;
        })
        builder.addCase(initializeTasksForCompany.rejected, (state, action) => {
            const companyId = action.meta.arg;
            state.loading[companyId] = false;
            state.error[companyId] = action.error.message || "Unknown error";
        })
        builder.addCase(cleanupTasks.fulfilled, (state, action) => {
            const companyId = action.meta.arg;
            delete state.items[companyId];
            delete state.loading[companyId];
            delete state.error[companyId];
        })
        builder.addCase(cleanupAllTasks.fulfilled, (state) => {
            state.items = {};
            state.loading = {};
            state.error = {};
        })
    }
})

export const { setTasks } = tasksSlice.actions;

export default tasksSlice.reducer;





