import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Project, ProjectThumbnail, UserRoleLevels } from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import {
  setDocumentWithHistory,
  updateDocumentsWithHistory,
  softDeleteDocumentWithHistory,
  fetchDocument,
  FirestoreDoc,
  fetchCollection
} from "@/app/lib/firebase/firebaseCRUD";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { WhereFilterOp, OrderByDirection } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectProjectById } from "@/app/lib/store/slices/projects/projectsSelectors";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<Project> | null } = {};

export interface ProjectsState {
  items: Record<string, Project>;
  closedItems: Record<string, Project>;
  isLoading: boolean;
  error: string | null;
}

const initialState: ProjectsState = {
  items: {},
  closedItems: {},
  isLoading: false,
  error: null,
};

export const initializeProjects = createAsyncThunk(
  "projects/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return state.projects.items || [];
    }

    const collectionPath = PATHS.collections.projects(companyId);
    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "isClosed", operator: "==" as WhereFilterOp, value: false },
      { field: "createdAt", direction: "desc" as OrderByDirection, type: "orderBy" as const },
    ];

    try {
      // Create a filter function based on user role
      const filterProjectsByUserRole = (projects: FirestoreDoc<Project>[], currentUserId: string, currentState: RootState) => {
        if (!currentUserId) {
          console.error("[projectsSlice] No user id found. c:PSL4");
          return [];
        }

        const userRole = currentState.app.selectedCompany?.userRole;

        if (!userRole) {
          console.error("[projectsSlice] No user role found. c:PSL3");
          return []
        }

        // If user is not a quality control user, return all projects
        if (userRole === UserRoleLevels.QUALITY_CONTROL) {
          return projects.filter(project => {
            const allowedUsersArray = Object.values(
              project.allowedQualityCheckUsersPerProductionStage || {}
            ).flatMap((users: { userId: string }[]) => users.map(user => user.userId));

            return allowedUsersArray.includes(currentUserId || "");
          });
        }

        if (userRole === UserRoleLevels.CUSTOMER) {
          const customerId = currentState.companyUsers.items[currentUserId]?.customerId;

          if (!customerId) {
            console.error("[projectsSlice] No customer id found. c:PSL5");
            return [];
          }

          const filteredProjects = projects.filter(project => {
            return project.customerId === customerId;
          })

          filteredProjects.forEach(project => {
            project.manufacturerId = "";
            project.customerId = "";
            project.ownerId = "";
            project.ownerEmail = "";
            project.allowedQualityCheckUsersPerProductionStage = {};
          })

          return filteredProjects;
        }

        return projects;
      };

      // Create subscription with 7-day caching and filter function
      const subscription = await useCollection<Project>(
        collectionPath,
        (projects) => {
          dispatch(setProjects({ companyId, projects }));
        },
        (error) => {
          console.error("Error fetching projects:", error);
          dispatch(setProjects({ companyId, projects: [] }));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        },
        undefined,
        (projects) => filterProjectsByUserRole(projects, userId, state)
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setProjects({ companyId, projects: [] }));
    }

    return state.projects.items || [];
  }
);

export const cleanupProjects = createAsyncThunk(
  "projects/cleanup",
  async (companyId: string) => {
    if (subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }
  }
);

export const cleanupAllProjects = createAsyncThunk(
  "projects/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

export const addProject = createAsyncThunk(
  "projects/add",
  async ({
    project
  }: {
    project: Project;
  }, { getState }) => {
    // TODO: make possible in firebaseCRUD to atomically change arrays of operations with different types, so we can update project and projectThumbnail atomically
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    const companyName = state.app.selectedCompany?.companyName ?? "";
    const customerName = state.customers.items[project.customerId]?.name ?? "";

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    // First create the project to get the ID
    const projectPath = PATHS.collections.projects(companyId);
    const projectOp = {
      path: projectPath,
      data: project,
      userId,
      type: {} as Project
    }

    const projectThumbnailPath = PATHS.collections.projectsThumbnails();
    const projectThumbnailObj: ProjectThumbnail = {
      id: project.id,
      companyId: companyId,
      companyName: companyName,
      projectName: project.name,
      customerId: project.customerId,
      customerName: customerName,
      imageUrls: project.imageUrls
    };
    const projectThumbnailOp = {
      path: projectThumbnailPath,
      data: projectThumbnailObj,
      userId: userId,
      type: {} as ProjectThumbnail
    }

    await setDocumentWithHistory<ProjectThumbnail>(projectThumbnailOp);
    const projectResult = await setDocumentWithHistory<Project>(projectOp);

    return projectResult;
  }
);

export const updateProject = createAsyncThunk(
  "projects/update",
  async ({
    updatedProject,
  }: {
    updatedProject: Project;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.project(companyId, updatedProject.id);
    const currentProject = selectProjectById(updatedProject.id)(state);

    if (!currentProject) {
      throw new Error("Project not found");
    }

    await updateDocumentsWithHistory<Project>([{
      path,
      oldData: currentProject,
      newData: updatedProject,
      userId
    }]);

    return updatedProject;
  }
);

export const deleteProject = createAsyncThunk(
  "projects/delete",
  async (projectId: string, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.project(companyId, projectId);

    await softDeleteDocumentWithHistory({
      path,
      userId,
    });

    return { companyId, projectId };
  }
);

export const fetchProject = createAsyncThunk(
  "projects/fetch",
  async (projectId: string, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected. c:PSL1");
    }

    // Check if project exists in store
    const storeProject = state.projects.items[projectId];
    const storeClosedProject = state.projects.closedItems[projectId];
    if (storeProject) {
      return storeProject;
    }

    if (storeClosedProject) {
      return storeClosedProject;
    }

    const path = PATHS.documents.project(companyId, projectId);
    const fetchedProject = await fetchDocument<Project>(path);

    if (!fetchedProject) {
      throw new Error("Project not found. c:PSL2");
    }

    dispatch(addProjectToStore({ project: fetchedProject }));

    return fetchedProject;
  }
);

export const fetchClosedProjects = createAsyncThunk(
  "projects/fetchClosed",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected. c:PSL1");
    }

    const path = PATHS.collections.projects(companyId);

    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "isClosed", operator: "==" as WhereFilterOp, value: true },
    ];

    const fetchedProjects = await fetchCollection<Project>({ collectionPath: path, queryConstraints });

    dispatch(setClosedProjects({ companyId, projects: fetchedProjects }));

    return fetchedProjects;
  }
);


const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (
      state,
      action: PayloadAction<{ companyId: string; projects: Project[] }>,
    ) => {
      const { projects } = action.payload;
      state.items = projects.reduce((acc, project) => {
        acc[project.id] = project;
        return acc;
      }, {} as Record<string, Project>);
      state.error = null;
    },
    setClosedProjects: (
      state,
      action: PayloadAction<{ companyId: string; projects: Project[] }>,
    ) => {
      const { projects } = action.payload;
      state.closedItems = projects.reduce((acc, project) => {
        acc[project.id] = project;
        return acc;
      }, {} as Record<string, Project>);
      state.error = null;
    },
    updateProjectState: (
      state,
      action: PayloadAction<{ companyId: string; project: Project }>,
    ) => {
      const { project } = action.payload;
      state.items[project.id] = project;
    },
    removeProject: (
      state,
      action: PayloadAction<{ companyId: string; projectId: string }>,
    ) => {
      const { projectId } = action.payload;
      delete state.items[projectId];
    },
    addProjectToStore: (
      state,
      action: PayloadAction<{ project: Project }>,
    ) => {
      const { project } = action.payload;
      if (project.isClosed) {
        state.closedItems[project.id] = project;
      } else {
        state.items[project.id] = project;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeProjects.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeProjects.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(initializeProjects.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(cleanupProjects.fulfilled, (state, action) => {
        state.items = {};
        state.closedItems = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(cleanupAllProjects.fulfilled, (state) => {
        state.items = {};
        state.closedItems = {};
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { setProjects, setClosedProjects, addProjectToStore, updateProjectState, removeProject } = projectsSlice.actions;

export default projectsSlice.reducer;
