import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GlobalSerialNumber, UsedSerialNumber } from "@/app/lib/interfaces";
import { setDocumentWithHistory, fetchDocument, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { RootState } from "@/app/lib/store/store";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<UsedSerialNumber> | null } = {};

export interface UsedSerialNumbersState {
  items: { [key: string]: UsedSerialNumber };
  isLoading: boolean;
  error: string | null;
}

const initialState: UsedSerialNumbersState = {
  items: {},
  isLoading: false,
  error: null,
};

export const addUsedSerialNumber = createAsyncThunk(
  "usedSerialNumbers/add",
  async ({
    usedSerialNumber
  }: {
    usedSerialNumber: Omit<UsedSerialNumber, "id">;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.collections.usedSerialNumbers(companyId);
    const newSerialNumber = await setDocumentWithHistory<UsedSerialNumber>({
      path,
      data: usedSerialNumber,
      userId
    });

    return newSerialNumber;
  },
);

export const addOrUpdateGlobalSerialNumber = createAsyncThunk(
  "globalSerialNumbers/addOrUpdate",
  async ({
    globalSerialNumber
  }: {
    globalSerialNumber: GlobalSerialNumber;
  }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { userId } = selectSelectedCompanyIdAndUserId(state);

    if (!userId) {
      throw new Error("No user selected");
    }

    const existingGlobalSerialNumber = await dispatch(fetchGlobalSerialNumber(globalSerialNumber.id)).unwrap();
    if (existingGlobalSerialNumber) {
      const updatedGlobalSerialNumber = {
        ...existingGlobalSerialNumber,
        checkResults: {
          ...existingGlobalSerialNumber.checkResults,
          ...globalSerialNumber.checkResults
        }
      }
      await updateDocumentsWithHistory<GlobalSerialNumber>([{
        path: PATHS.documents.globalSerialNumber(existingGlobalSerialNumber.id),
        oldData: existingGlobalSerialNumber,
        newData: updatedGlobalSerialNumber,
        userId
      }])
    } else {
      await setDocumentWithHistory({
        path: PATHS.collections.globalSerialNumbers(),
        data: globalSerialNumber,
        userId
      })
    }
  },
);

export const fetchGlobalSerialNumber = createAsyncThunk(
  "globalSerialNumbers/fetch",
  async (serialNumber: string): Promise<GlobalSerialNumber | null> => {
    const path = PATHS.documents.globalSerialNumber(serialNumber);
    const globalSerialNumber = await fetchDocument<GlobalSerialNumber>(path);
    if (!globalSerialNumber) {
      return null;
    }
    return globalSerialNumber;
  }
);

export const initializeUsedSerialNumbers = createAsyncThunk(
  "usedSerialNumbers/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return state.usedSerialNumbers.items;
    }

    const collectionPath = PATHS.collections.usedSerialNumbers(companyId);

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<UsedSerialNumber>(
        collectionPath,
        (usedSerialNumbers) => {
          dispatch(setUsedSerialNumbers(usedSerialNumbers));
        },
        (error) => {
          console.error("Error fetching used serial numbers:", error);
          dispatch(setUsedSerialNumbers([]));
        },
        undefined,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setUsedSerialNumbers([]));
    }

    return state.usedSerialNumbers.items;
  }
);

export const cleanupUsedSerialNumbers = createAsyncThunk(
  "usedSerialNumbers/cleanup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (companyId && subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }
  }
);

export const cleanupAllUsedSerialNumbers = createAsyncThunk(
  "usedSerialNumbers/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

const usedSerialNumbersSlice = createSlice({
  name: "usedSerialNumbers",
  initialState,
  reducers: {
    setUsedSerialNumbers: (
      state,
      action: PayloadAction<UsedSerialNumber[]>,
    ) => {
      state.items = action.payload.reduce(
        (acc, usedSerialNumber) => {
          acc[usedSerialNumber.id] = usedSerialNumber;
          return acc;
        },
        {} as { [key: string]: UsedSerialNumber },
      );
      state.error = null;
    },
    updateUsedSerialNumber: (
      state,
      action: PayloadAction<UsedSerialNumber>,
    ) => {
      state.items[action.payload.id] = action.payload;
    },
    removeUsedSerialNumber: (state, action: PayloadAction<string>) => {
      delete state.items[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeUsedSerialNumbers.pending, (state) => {
        state.items = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeUsedSerialNumbers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(initializeUsedSerialNumbers.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(cleanupUsedSerialNumbers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(cleanupAllUsedSerialNumbers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addUsedSerialNumber.fulfilled, (state, action) => {
        if (action.payload) {
          state.items[action.payload.id] = action.payload;
        }
      })
  },
});

export const {
  setUsedSerialNumbers,
  updateUsedSerialNumber,
  removeUsedSerialNumber,
} = usedSerialNumbersSlice.actions;

export default usedSerialNumbersSlice.reducer;
