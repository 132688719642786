import { DateService } from "@/app/lib/services/date.service";
import { RootState } from "@/app/lib/store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectTasksState = (state: RootState) => state.tasks;

export const selectAllTasks = createSelector(
    selectTasksState,
    (state) => state.items
);

export const selectAllTasksPerCompany = (companyId: string) => createSelector(
    selectAllTasks,
    (items) => items[companyId] || []
);

export const selectPendingOrProgressTasksStatisticsForCurrentUser = (companyId: string, userId: string) => createSelector(
    selectAllTasks,
    (items) => {
        const tasks = items[companyId]

        if (!tasks) return { total: 0, dueIn2Days: 0, dueIn1Day: 0, overdue: 0 };

        const taskArray = Object.values(tasks).filter((task) => task.status === "pending" || task.status === "in_progress");

        const filteredTasks = taskArray.filter((task) => task.ownerId === userId || task.createdBy === userId || task.assignedTo?.includes(userId));

        // If no tasks match our criteria, return early with zeros
        if (filteredTasks.length === 0) {
            return { total: 0, dueIn2Days: 0, dueIn1Day: 0, overdue: 0 };
        }

        let dueIn2Days = 0;
        let dueIn1Day = 0;
        let overdue = 0;

        filteredTasks.forEach((task) => {
            if (task.dueAt) {
                const timeUntilDue = DateService.getTimeUntilDate(task.dueAt);
                if (timeUntilDue.ms <= 0) {
                    overdue++;
                } else if (timeUntilDue.ms <= 86400000) { // 1 day
                    dueIn1Day++;
                } else if (timeUntilDue.ms <= 172800000) { // 2 days
                    dueIn2Days++;
                }
            }
        });

        const total = filteredTasks.length;

        return {
            total,
            dueIn2Days,
            dueIn1Day,
            overdue
        };
    }
);

export const selectTasksLoading = (companyId: string) => createSelector(
    selectTasksState,
    (state) => state.loading[companyId] || false
);

export const selectTaskById = (taskId: string) => createSelector(
    selectTasksState,
    (state) => {
        const mergedTasks = Object.values(state.items).reduce((acc, companyTasks) => {
            return { ...acc, ...companyTasks };
        }, {});
        return mergedTasks[taskId];
    }
);

