import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  ProductionStage,
  AllowedQualityCheckUserDetails,
  Project,
  PaperQualityCheck,
} from "../../../interfaces";
import { RootState } from "../../store";
import { getAllChecklistItems } from "../checklists/checklistsSlice";
import { setDocumentWithHistory, updateDocumentsWithHistory, softDeleteDocumentWithHistory, fetchDocument, fetchCollection } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp, OrderByDirection } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectProjectProductionStageById } from "./productionStagesSelectors";
import { selectProjectById } from "../projects/projectsSelectors";
import { updateProject } from "../projects/projectsSlice";
import { AtLeast } from "@/app/lib/types/global";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [projectId: string]: CollectionSubscription<ProductionStage> | null } = {};

export interface ProductionStagesState {
  items: {
    [projectId: string]: ProductionStage[];
  };
  isLoading: {
    [projectId: string]: boolean;
  };
  error: {
    [projectId: string]: string | null;
  };
}

const initialState: ProductionStagesState = {
  items: {},
  isLoading: {},
  error: {},
};

export const initializeProductionStages = createAsyncThunk(
  "productionStages/initialize",
  async (projectId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this project
    if (subscriptions[projectId]) {
      return state.productionStages.items[projectId] || [];
    }

    const collectionPath = PATHS.collections.productionStages(companyId, projectId);
    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "order", direction: "desc" as OrderByDirection, type: "orderBy" as const }
    ];

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<ProductionStage>(
        collectionPath,
        (productionStages) => {
          dispatch(setProductionStages({ projectId, productionStages }));

          // Fetch checklists for each stage
          productionStages.forEach(stage => {
            dispatch(getAllChecklistItems({
              projectId,
              productionStageId: stage.id,
            }));
          });
        },
        (error) => {
          console.error("Error fetching production stages:", error);
          dispatch(setProductionStages({ projectId, productionStages: [] }));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[projectId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setProductionStages({ projectId, productionStages: [] }));
    }

    return state.productionStages.items[projectId] || [];
  }
);

export const cleanupProductionStages = createAsyncThunk(
  "productionStages/cleanup",
  async (projectId: string) => {
    if (subscriptions[projectId]) {
      subscriptions[projectId]!.unsubscribe();
      subscriptions[projectId] = null;
    }
  },
);

export const cleanupAllProductionStages = createAsyncThunk(
  "productionStages/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((projectId) => {
      if (subscriptions[projectId]) {
        subscriptions[projectId]!.unsubscribe();
        subscriptions[projectId] = null;
      }
    });
    subscriptions = {};
  },
);

// Helper function to check if a project has an active subscription
export const hasActiveSubscription = (projectId: string) => {
  return !!subscriptions[projectId];
};

export const addProductionStage = createAsyncThunk(
  "productionStages/add",
  async ({
    productionStage,
    allowedQualityCheckUsers,
    projectId,
  }: {
    productionStage: Omit<ProductionStage, "id">;
    allowedQualityCheckUsers: AllowedQualityCheckUserDetails[];
    projectId: string;
  }, { dispatch, getState }) => {
    // TODO: refactor to set(update) production stage and project.allowedQualityCheckUsers atomically
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const prodStPath = PATHS.collections.productionStages(companyId, projectId);
    const newProdStage = await setDocumentWithHistory<ProductionStage>({
      path: prodStPath,
      data: productionStage,
      userId
    });

    const currentProject = selectProjectById(projectId)(state);
    if (!currentProject) {
      throw new Error("Project not found");
    }

    const updatedProject: Project = {
      ...currentProject,
      allowedQualityCheckUsersPerProductionStage: {
        ...currentProject.allowedQualityCheckUsersPerProductionStage,
        [newProdStage.id]: allowedQualityCheckUsers
      }
    }

    await dispatch(
      updateProject({
        updatedProject
      })
    ).unwrap();

    return {
      projectId,
      productionStage: newProdStage
    };
  }
);

export const deleteProductionStage = createAsyncThunk(
  "productionStages/delete",
  async (
    {
      projectId,
      productionStageId,
    }: {
      projectId: string;
      productionStageId: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    await softDeleteDocumentWithHistory({
      path: PATHS.documents.productionStage(companyId, projectId, productionStageId),
      userId,
    });

    const currentProject = selectProjectById(projectId)(state);
    if (!currentProject) {
      throw new Error("Project not found");
    }

    const updatedProject: Project = {
      ...currentProject,
      allowedQualityCheckUsersPerProductionStage: {
        ...currentProject.allowedQualityCheckUsersPerProductionStage,
        [productionStageId]: []
      }
    }

    await dispatch(
      updateProject({
        updatedProject
      })
    ).unwrap();

    return { projectId, productionStageId };
  },
);

export const updateProductionStage = createAsyncThunk(
  "productionStages/update",
  async ({
    projectId,
    productionStage,
    allowedQualityCheckUsers,
  }: {
    projectId: string;
    productionStage: AtLeast<ProductionStage, "id">;
    allowedQualityCheckUsers: AllowedQualityCheckUserDetails[];
  }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.productionStage(companyId, projectId, productionStage.id);
    const originalProdStage = selectProjectProductionStageById(projectId, productionStage.id)(state);

    if (!originalProdStage) {
      throw new Error("Production stage not found");
    }

    const prodStageToUpdate: ProductionStage = {
      ...originalProdStage,
      ...productionStage,
    }

    await updateDocumentsWithHistory([{
      path,
      oldData: originalProdStage,
      newData: prodStageToUpdate,
      userId
    }]);

    const currentProject = selectProjectById(projectId)(state);
    if (!currentProject) {
      throw new Error("Project not found");
    }

    const updatedProject: Project = {
      ...currentProject,
      allowedQualityCheckUsersPerProductionStage: {
        ...currentProject.allowedQualityCheckUsersPerProductionStage,
        [productionStage.id]: allowedQualityCheckUsers
      }
    }

    await dispatch(
      updateProject({
        updatedProject
      })
    ).unwrap();

    return { projectId, productionStage };
  }
);

/**
 * Fetches a single production stage by ID.
 * First checks if the stage exists in the Redux store.
 * If not found in store, fetches from Firestore.
 * Requires a selected company context.
 *
 * @param projectId - ID of the project containing the production stage
 * @param productionStageId - ID of the production stage to fetch
 * @returns The production stage if found, null if not found
 * @throws Error if no company is selected (PSL1) or stage not found (PSL2)
 */
export const fetchProductionStage = createAsyncThunk(
  "productionStages/fetch",
  async (
    {
      projectId,
      productionStageId,
    }: {
      projectId: string;
      productionStageId: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const companyId = state.app.selectedCompany?.companyId;

    if (!companyId) {
      throw new Error("No company selected. c:PSL1");
    }

    // 1. check if the stage is already in the store
    const stage = state.productionStages.items[projectId]?.find(
      (s) => s.id === productionStageId,
    );
    if (stage) {
      return stage;
    }

    const productionStage = await fetchDocument<ProductionStage>(
      PATHS.documents.productionStage(companyId, projectId, productionStageId),
    );

    if (!productionStage) {
      throw new Error("Production stage not found. c:PSL2");
    }

    return productionStage;
  },
);

const productionStagesSlice = createSlice({
  name: "productionStages",
  initialState,
  reducers: {
    setProductionStages: (
      state,
      action: PayloadAction<{
        projectId: string;
        productionStages: ProductionStage[];
      }>,
    ) => {
      const { projectId, productionStages } = action.payload;

      // Preserve any temp items in the checklist during Firebase updates
      const existingStages = state.items[projectId] || [];
      const updatedStages = productionStages.map((newStage) => {
        const existingStage = existingStages.find((s) => s.id === newStage.id);
        // Ensure pdfDocuments is always an array
        const pdfDocuments =
          newStage.pdfDocuments || existingStage?.pdfDocuments || [];

        return {
          ...newStage,
          pdfDocuments,
        };
      });
      state.items[projectId] = updatedStages;
      state.error[projectId] = null;
    },
    updateProductionStage: (
      state,
      action: PayloadAction<{
        projectId: string;
        productionStage: ProductionStage;
      }>,
    ) => {
      const { projectId, productionStage } = action.payload;
      const index = state.items[projectId]?.findIndex(
        (c) => c.id === productionStage.id,
      );
      if (index !== undefined && index !== -1) {
        state.items[projectId][index] = productionStage;
      }
    },
    removeProductionStage: (
      state,
      action: PayloadAction<{ projectId: string; productionStageId: string }>,
    ) => {
      const { projectId, productionStageId } = action.payload;
      const productionStages = state.items[projectId];
      if (productionStages) {
        state.items[projectId] = productionStages.filter(
          (productionStage) => productionStage.id !== productionStageId,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeProductionStages.pending, (state, action) => {
        const projectId = action.meta.arg;
        if (!state.items[projectId]) {
          state.items[projectId] = [];
        }
        state.isLoading[projectId] = true;
        state.error[projectId] = null;
      })
      .addCase(initializeProductionStages.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        state.isLoading[projectId] = false;
      })
      .addCase(initializeProductionStages.rejected, (state, action) => {
        const projectId = action.meta.arg;
        state.error[projectId] = action.error.message || "Unknown error";
        state.isLoading[projectId] = false;
      })
      .addCase(cleanupProductionStages.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        delete state.items[projectId];
        delete state.isLoading[projectId];
        delete state.error[projectId];
      })
      .addCase(cleanupAllProductionStages.fulfilled, (state) => {
        state.items = {};
        state.isLoading = {};
        state.error = {};
      })
      .addCase(updateProductionStage.fulfilled, (state, action) => {
        const { projectId, productionStage } = action.payload;
        const stages = state.items[projectId];
        if (stages) {
          const index = stages.findIndex((s) => s.id === productionStage.id);
          if (index !== -1) {
            stages[index] = {
              ...stages[index],
              ...productionStage,
              pdfDocuments: productionStage.pdfDocuments || [],
            };
          }
        }
      });
  },
});

export const {
  setProductionStages,
  updateProductionStage: updateProductionStageAction,
  removeProductionStage,
} = productionStagesSlice.actions;

export default productionStagesSlice.reducer;

