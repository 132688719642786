import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Batch } from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import { setDocumentWithHistory, updateDocumentsWithHistory, softDeleteDocumentWithHistory, fetchDocument } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp, OrderByDirection } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectBatchById } from "@/app/lib/store/slices/batches/batchesSelectors";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [projectId: string]: CollectionSubscription<Batch> | null } = {};

export interface BatchesState {
  items: {
    [projectId: string]: Batch[];
  };
  isLoading: {
    [projectId: string]: boolean;
  };
  error: {
    [projectId: string]: string | null;
  };
}

const initialState: BatchesState = {
  items: {},
  isLoading: {},
  error: {},
};

export const initializeBatches = createAsyncThunk(
  "batches/initialize",
  async (projectId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this project
    if (subscriptions[projectId]) {
      return state.batches.items[projectId] || [];
    }

    const collectionPath = PATHS.collections.batches(companyId, projectId);
    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
      { field: "createdAt", direction: "desc" as OrderByDirection, type: "orderBy" as const }
    ];

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<Batch>(
        collectionPath,
        (batches) => {
          dispatch(setBatches({ projectId, batches }));
        },
        (error) => {
          console.error("Error fetching batches:", error);
          dispatch(setBatches({ projectId, batches: [] }));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[projectId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setBatches({ projectId, batches: [] }));
    }

    return state.batches.items[projectId] || [];
  }
);

export const cleanupBatches = createAsyncThunk(
  "batches/cleanup",
  async (projectId: string) => {
    if (subscriptions[projectId]) {
      subscriptions[projectId]!.unsubscribe();
      subscriptions[projectId] = null;
    }
  },
);

export const cleanupAllBatches = createAsyncThunk(
  "batches/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((projectId) => {
      if (subscriptions[projectId]) {
        subscriptions[projectId]!.unsubscribe();
        subscriptions[projectId] = null;
      }
    });
    subscriptions = {};
  },
);

export const hasActiveSubscription = (projectId: string) => {
  return !!subscriptions[projectId];
};

export const addBatch = createAsyncThunk(
  "batches/add",
  async ({
    batch,
    projectId,
  }: {
    batch: Omit<Batch, "id">;
    projectId: string;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.collections.batches(companyId, projectId);
    const newBatch = await setDocumentWithHistory<Batch>({
      path,
      data: batch,
      userId
    });
    return {
      projectId,
      batch: newBatch
    };
  }
);

export const updateBatch = createAsyncThunk(
  "batches/update",
  async ({
    projectId,
    partialBatch,
    batchId,
  }: {
    projectId: string;
    partialBatch: Partial<Batch>;
    batchId: string;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.batch(companyId, projectId, batchId);
    const currentBatch = selectBatchById(projectId, batchId)(state);

    if (!currentBatch) {
      throw new Error("Batch not found");
    }

    const updatedBatch = {
      ...currentBatch,
      ...partialBatch,
    };

    await updateDocumentsWithHistory([{
      path,
      oldData: currentBatch,
      newData: updatedBatch,
      userId
    }]);

    return { projectId, updatedBatch };
  }
);

export const deleteBatch = createAsyncThunk(
  "batches/delete",
  async ({
    projectId,
    batchId,
  }: {
    projectId: string;
    batchId: string;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.batch(companyId, projectId, batchId);

    await softDeleteDocumentWithHistory({
      path,
      userId,
    });

    return { projectId, batchId };
  }
);

/**
 * Fetches a single batch by ID.
 * First checks if the batch exists in the Redux store.
 * If not found in store, fetches from Firestore.
 * Requires a selected company context.
 *
 * @param projectId - ID of the project containing the batch
 * @param batchId - ID of the batch to fetch
 * @returns The batch if found, null if not found
 * @throws Error if no company is selected (BSL1)
 */
export const fetchBatch = createAsyncThunk(
  "batches/fetch",
  async (
    {
      projectId,
      batchId,
    }: {
      projectId: string;
      batchId: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected. c:BSL1");
    }

    //1. check if the batch is already in the store
    const storeBatch = state.batches.items[projectId]?.find((b) => b.id === batchId);
    if (storeBatch) {
      return storeBatch;
    }

    const path = PATHS.documents.batch(companyId, projectId, batchId);
    const fetchedBatch = await fetchDocument<Batch>(path);

    return fetchedBatch;
  },
);

const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    setBatches: (
      state,
      action: PayloadAction<{ projectId: string; batches: Batch[] }>,
    ) => {
      const { projectId, batches } = action.payload;
      state.items[projectId] = batches;
      state.error[projectId] = null;
    },
    updateBatchState: (
      state,
      action: PayloadAction<{ projectId: string; batch: Batch }>,
    ) => {
      const { projectId, batch } = action.payload;
      const index = state.items[projectId]?.findIndex((b) => b.id === batch.id);
      if (index !== undefined && index !== -1) {
        state.items[projectId][index] = batch;
      }
    },
    removeBatch: (
      state,
      action: PayloadAction<{ projectId: string; batchId: string }>,
    ) => {
      const { projectId, batchId } = action.payload;
      const batches = state.items[projectId];
      if (batches) {
        state.items[projectId] = batches.filter(
          (batch) => batch.id !== batchId,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeBatches.pending, (state, action) => {
        const projectId = action.meta.arg;
        if (!state.items[projectId]) {
          state.items[projectId] = [];
        }
        state.isLoading[projectId] = true;
        state.error[projectId] = null;
      })
      .addCase(initializeBatches.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        state.isLoading[projectId] = false;
      })
      .addCase(initializeBatches.rejected, (state, action) => {
        const projectId = action.meta.arg;
        state.error[projectId] = action.error.message || "Unknown error";
        state.isLoading[projectId] = false;
      })
      .addCase(cleanupBatches.fulfilled, (state, action) => {
        const projectId = action.meta.arg;
        delete state.items[projectId];
        delete state.isLoading[projectId];
        delete state.error[projectId];
      })
      .addCase(cleanupAllBatches.fulfilled, (state) => {
        state.items = {};
        state.isLoading = {};
        state.error = {};
      });
  },
});

export const { setBatches, updateBatchState, removeBatch } =
  batchesSlice.actions;

export default batchesSlice.reducer;
