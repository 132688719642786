import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";

// Base selector
const selectBatchesState = (state: RootState) => state.batches;

export const selectAllBatches = createSelector(
  selectBatchesState,
  (state) => state.items
);

export const selectAllBatchesArray = createSelector(
  selectAllBatches,
  (items) => Object.values(items).flat()
);

export const selectProjectBatches = (projectId: string) => createSelector(
  selectAllBatches,
  (items) => items[projectId] || []
);

// Select loading state for a specific project
export const selectBatchesLoading = (projectId: string) => createSelector(
  selectBatchesState,
  (state) => state.isLoading[projectId] || false
);

export const selectBatchById = (projectId: string, batchId: string) => createSelector(
  selectProjectBatches(projectId),
  (batches) => batches.find((batch) => batch.id === batchId) || null
);

export const selectBatchName = (batchId: string) => createSelector(
  selectAllBatchesArray,
  (batches) => batches.find((batch) => batch.id === batchId)?.name || null
);
