import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/lib/store/store";
import { Answer } from "@/app/lib/interfaces";
import { PATHS } from "@/app/lib/firebase/constants";
import { fetchCollection, fetchDocument, setDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { removeUndefined } from "@/app/lib/firebase/firebaseFunctions";

export interface QualityCheckAnswersState {
  answers: Answer[];
  loading: boolean;
  error: string | null;
}

const initialState: QualityCheckAnswersState = {
  answers: [],
  loading: false,
  error: null,
};

export const cleanupAllAnswers = createAsyncThunk(
  "qualityCheckAnswers/cleanupAll",
  async () => {
    // Reset all answers data
  }
);

export const getAnswersForCheck = createAsyncThunk(
  "qualityChecks/getAnswers",
  async (
    { checkId }: { checkId: string },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId) {
      throw new Error("No company selected");
    }

    const answers = await fetchCollection<Answer>(
      {
        collectionPath: `${PATHS.collections.qualityChecks(companyId)}/${checkId}/answers`,
        queryConstraints: [
          {
            field: "isSoftDeleted",
            operator: "==",
            value: false
          }
        ]
      }
    );
    return answers;
  },
);

export const getAnswerById = createAsyncThunk(
  "qualityChecks/getAnswerById",
  async (
    { checkId, answerId }: { checkId: string; answerId: string },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId) {
      throw new Error("No company selected");
    }

    const answer = await fetchDocument<Answer>(
      `${PATHS.collections.qualityChecks(companyId)}/${checkId}/answers/${answerId}`
    );
    return answer;
  },
);

export const addAnswerForCheck = createAsyncThunk(
  "qualityChecks/addAnswer",
  async (
    {
      projectId,
      checkId,
      answer,
    }: {
      projectId: string;
      checkId: string;
      answer: Omit<Answer, "id">;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    console.log("[addAnswerForCheck] answer", answer);

    const answerWithId = await setDocumentWithHistory({
      path: `${PATHS.collections.qualityChecks(companyId)}/${checkId}/answers`,
      data: answer,
      userId
    });

    return answerWithId;
  },
);

export const updateAnswerForCheck = createAsyncThunk(
  "qualityChecks/updateAnswer",
  async (
    {
      checkId,
      originalAnswer,
      answer,
    }: {
      checkId: string;
      originalAnswer?: Answer;
      answer: Answer;
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);
    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    if (!originalAnswer) {
      const result = await dispatch(getAnswerById({ checkId, answerId: answer.id })).unwrap();
      if (result) {
        originalAnswer = result;
      }
    }

    if (!originalAnswer) {
      throw new Error("[qualityChecks/updateAnswer] Original answer not found");
    }

    await updateDocumentsWithHistory<Answer>([
      {
        path: `${PATHS.collections.qualityChecks(companyId)}/${checkId}/answers/${answer.id}`,
        oldData: originalAnswer,
        newData: answer,
        userId
      }
    ]);
  },
);

const qualityCheckAnswersSlice = createSlice({
  name: "qualityCheckAnswers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get answers
    builder
      .addCase(getAnswersForCheck.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAnswersForCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.answers = action.payload;
      })
      .addCase(getAnswersForCheck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch answers";
      })

    // Cleanup all answers
    builder
      .addCase(cleanupAllAnswers.fulfilled, (state) => {
        state.answers = [];
        state.loading = false;
        state.error = null;
      });
  },
});

export default qualityCheckAnswersSlice.reducer;
