import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Base selector
const selectAppState = (state: RootState) => state.app;

// export const selectUser = createSelector(
//   selectAppState,
//   (state) => state.user,
// );

// Select selected company
export const selectSelectedCompany = createSelector(
  selectAppState,
  (state) => state.selectedCompany,
);

export const selectUserInformation = createSelector(
  selectAppState,
  (state) => state.userInformation,
);

export const selectSelectedCompanyIdAndUserId = createSelector(
  selectSelectedCompany,
  selectUserInformation,
  (selectedCompany, userInformation) => ({
    companyId: selectedCompany?.companyId,
    userId: userInformation?.id,
  }),
);

// Select companies
export const selectCompanies = createSelector(
  selectAppState,
  (state) => state.userInformation?.companies,
);

// Select initialization state
export const selectIsInitialized = createSelector(
  selectAppState,
  (state) => state.isInitialized,
);

// Select loading state
export const selectIsLoading = createSelector(
  selectAppState,
  (state) => state.isLoading,
);

// Select error state
export const selectError = createSelector(
  selectAppState,
  (state) => state.error,
);

// Select operating system
export const selectOperatingSystem = createSelector(
  selectAppState,
  (state) => state.operatingSystem,
);

// Select unsaved changes state
export const selectCurrentPageHasUnsavedChanges = createSelector(
  selectAppState,
  (state) => state.currentPageHasUnsavedChanges,
);
