import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CompanyInformation } from "@/app/lib/interfaces";
import { RootState } from "../../store";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";
import { updateDocumentsWithHistory, subscribeToDocumentWithRetry } from "@/app/lib/firebase/firebaseCRUD";
import { PATHS } from "@/app/lib/firebase/constants";

let unsubscribe: (() => void) | null = null;

export interface CompanyInformationState {
  data: CompanyInformation | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: CompanyInformationState = {
  data: null,
  isLoading: false,
  error: null,
};

export const updateQrCodeEmbeddings = createAsyncThunk(
  "companyInformation/updateQrCodeEmbeddings",
  async ({
    qrCodeEmbeddings,
  }: {
    qrCodeEmbeddings: string[];
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("Company ID or user ID is required");
    }

    let currentCompanyInformation = state.companyInformation.data;
    if (!currentCompanyInformation) {
      throw new Error("Company information not found");
    }

    const newCompanyInforamation = {
      ...currentCompanyInformation,
      qrCodeEmbeddings,
    };

    await updateDocumentsWithHistory([{
      path: PATHS.documents.company(companyId),
      oldData: currentCompanyInformation,
      newData: newCompanyInforamation,
      userId,
    }]);

    return qrCodeEmbeddings;
  },
);

export const updateTotalPaperQualityCheckBarcodes = createAsyncThunk(
  "companyInformation/updateTotalPaperQualityCheckBarcodes",
  async ({
    totalPaperQualityCheckBarcodes,
  }: {
    totalPaperQualityCheckBarcodes: number;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("Company ID or user ID is required");
    }

    let currentCompanyInformation = state.companyInformation.data;
    if (!currentCompanyInformation) {
      throw new Error("Company information not found");
    }

    const newCompanyInformation = {
      ...currentCompanyInformation,
      totalPaperQualityCheckBarcodes,
    };

    console.log(">>>>>>>>> newCompanyInformation", newCompanyInformation);
    await updateDocumentsWithHistory([{
      path: PATHS.documents.company(companyId),
      oldData: currentCompanyInformation,
      newData: newCompanyInformation,
      userId,
    }]);
  },
);

export const initializeCompanyInformation = createAsyncThunk(
  "companyInformation/initialize",
  async (_, { dispatch, getState }) => {
    const { companyId } = selectSelectedCompanyIdAndUserId(getState() as RootState);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Clean up previous subscription if exists
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }

    return new Promise<void>((resolve, reject) => {
      try {
        unsubscribe = subscribeToDocumentWithRetry<CompanyInformation>(
          PATHS.documents.company(companyId),
          (companyData) => {
            if (companyData) {
              dispatch(setCompanyInformation(companyData));
            }
            resolve();
          },
          (error) => {
            console.error("Error fetching company information:", error);
            reject(error);
          }
        );
      } catch (error) {
        console.error(
          "Error setting up company information subscription:",
          error,
        );
        reject(error);
      }
    });
  },
);

export const cleanupCompanyInformation = createAsyncThunk(
  "companyInformation/cleanup",
  async () => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
    }
  },
);

const companyInformationSlice = createSlice({
  name: "companyInformation",
  initialState,
  reducers: {
    setCompanyInformation: (state, action: PayloadAction<CompanyInformation>) => {
      state.data = action.payload;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize company information
      .addCase(initializeCompanyInformation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeCompanyInformation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(initializeCompanyInformation.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Update QR code embeddings
      .addCase(updateQrCodeEmbeddings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateQrCodeEmbeddings.fulfilled, (state, action) => {
        if (state.data) {
          state.data.qrCodeEmbeddings = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(updateQrCodeEmbeddings.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      // Cleanup
      .addCase(cleanupCompanyInformation.fulfilled, (state) => {
        state.data = null;
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { setCompanyInformation } = companyInformationSlice.actions;

export default companyInformationSlice.reducer;
