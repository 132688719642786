import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "@/app/lib/interfaces";
import { RootState } from "@/app/lib/store/store";
import { setDocumentWithHistory, updateDocumentsWithHistory, softDeleteDocumentWithHistory, fetchDocument } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp } from "firebase/firestore";
import { PATHS } from "@/app/lib/firebase/constants";
import { selectSelectedCompanyIdAndUserId } from "@/app/lib/store/slices/appSelectors";
import { selectCustomerById } from "@/app/lib/store/slices/customers/customersSelectors";
import { removeUndefined } from "@/app/lib/firebase/firebaseFunctions";
import { useCollection } from "@/app/lib/firebase/firebaseSubscriptions";
import { CollectionSubscription } from "@/app/lib/firebase/services/SubscriptionService";

// Track active subscriptions
let subscriptions: { [companyId: string]: CollectionSubscription<Customer> | null } = {};

export interface CustomersState {
  items: { [key: string]: Customer };
  isLoading: boolean;
  error: string | null;
}

const initialState: CustomersState = {
  items: {},
  isLoading: false,
  error: null,
};

export const initializeCustomers = createAsyncThunk(
  "customers/initialize",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if we already have an active subscription for this company
    if (subscriptions[companyId]) {
      return state.customers.items;
    }

    const collectionPath = PATHS.collections.customers(companyId);
    const queryConstraints = [
      { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false }
    ];

    try {
      // Create subscription with 7-day caching
      const subscription = await useCollection<Customer>(
        collectionPath,
        (customers) => {
          dispatch(setCustomers(customers));
        },
        (error) => {
          console.error("Error fetching customers:", error);
          dispatch(setCustomers([]));
        },
        queryConstraints,
        {
          useCache: true,
          cacheTTL: 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
        }
      );

      // Store the subscription for cleanup later
      subscriptions[companyId] = subscription;
    } catch (error) {
      console.error("Error creating subscription:", error);
      dispatch(setCustomers([]));
    }

    return state.customers.items;
  }
);

export const cleanupCustomers = createAsyncThunk(
  "customers/cleanup",
  async (companyId: string) => {
    if (subscriptions[companyId]) {
      subscriptions[companyId]!.unsubscribe();
      subscriptions[companyId] = null;
    }
  }
);

export const cleanupAllCustomers = createAsyncThunk(
  "customers/cleanupAll",
  async () => {
    Object.keys(subscriptions).forEach((companyId) => {
      if (subscriptions[companyId]) {
        subscriptions[companyId]!.unsubscribe();
        subscriptions[companyId] = null;
      }
    });
    subscriptions = {};
  }
);

export const hasActiveSubscription = (companyId: string) => {
  return !!subscriptions[companyId];
};

export const addCustomer = createAsyncThunk(
  "customers/add",
  async ({
    customer,
  }: {
    customer: Omit<Customer, "id">;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.collections.customers(companyId);
    const customerWithoutUndefined = removeUndefined(customer);
    const newCustomer = await setDocumentWithHistory<Customer>({
      path,
      data: customerWithoutUndefined,
      userId
    });

    return newCustomer;
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/update",
  async ({
    updatedCustomer,
  }: {
    updatedCustomer: Customer;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.customer(companyId, updatedCustomer.id);
    const currentCustomer = selectCustomerById(updatedCustomer.id)(state);

    if (!currentCustomer) {
      throw new Error("Customer not found");
    }

    await updateDocumentsWithHistory([{
      path,
      oldData: currentCustomer,
      newData: updatedCustomer,
      userId
    }]);

    return updatedCustomer;
  }
);

export const deleteCustomer = createAsyncThunk(
  "customers/delete",
  async ({
    customerId,
  }: {
    customerId: string;
  }, { getState }) => {
    const state = getState() as RootState;
    const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId || !userId) {
      throw new Error("No company or user selected");
    }

    const path = PATHS.documents.customer(companyId, customerId);

    await softDeleteDocumentWithHistory({
      path,
      userId,
    });

    return customerId;
  }
);

export const fetchCustomer = createAsyncThunk(
  "customers/fetch",
  async (
    {
      customerId,
    }: {
      customerId: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const { companyId } = selectSelectedCompanyIdAndUserId(state);

    if (!companyId) {
      throw new Error("No company selected");
    }

    // Check if the customer is already in the store
    const storeCustomer = state.customers.items[customerId];
    if (storeCustomer) {
      return storeCustomer;
    }

    const path = PATHS.documents.customer(companyId, customerId);
    const fetchedCustomer = await fetchDocument<Customer>(path);

    return fetchedCustomer;
  },
);

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (state, action: PayloadAction<Customer[]>) => {
      const customersMap: { [key: string]: Customer } = {};
      action.payload.forEach((customer) => {
        customersMap[customer.id] = customer;
      });
      state.items = customersMap;
      state.error = null;
    },
    updateCustomerState: (state, action: PayloadAction<Customer>) => {
      state.items[action.payload.id] = action.payload;
    },
    removeCustomer: (state, action: PayloadAction<string>) => {
      delete state.items[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeCustomers.pending, (state) => {
        state.items = {};
        state.isLoading = true;
        state.error = null;
      })
      .addCase(initializeCustomers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(initializeCustomers.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(cleanupCustomers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(cleanupAllCustomers.fulfilled, (state) => {
        state.items = {};
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.items[action.payload.id] = action.payload;
        state.isLoading = false;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.items[action.payload.id] = action.payload;
        state.isLoading = false;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        delete state.items[action.payload];
        state.isLoading = false;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.error = action.error.message || "Unknown error";
        state.isLoading = false;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        if (action.payload) {
          state.items[action.payload.id] = action.payload;
        }
      });
  },
});

export const { setCustomers, updateCustomerState, removeCustomer } =
  customersSlice.actions;

export default customersSlice.reducer;
