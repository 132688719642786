import { PaperQualityCheck } from "@/app/lib/interfaces";
import { RootState } from "../../store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PATHS } from "@/app/lib/firebase/constants";
import { fetchCollection, setDocumentWithHistory, updateDocumentsWithHistory } from "@/app/lib/firebase/firebaseCRUD";
import { WhereFilterOp } from "firebase/firestore";
import { selectSelectedCompanyIdAndUserId } from "../appSelectors";

export interface PaperQualityCheckState {
    items: PaperQualityCheck[];
    isLoading: boolean;
    error: string | null;
}

const initialState: PaperQualityCheckState = {
    items: [],
    isLoading: false,
    error: null,
};

export const fetchPaperQualityCheckObjects = createAsyncThunk(
    "productionStages/fetchPaperQualityCheckObjects",
    async (
        _,
        { getState }
    ) => {
        const state = getState() as RootState;
        const companyId = state.app.selectedCompany?.companyId;

        if (!companyId) {
            throw new Error("No company selected. c:PSL1");
        }

        const paperQualityChecks = await fetchCollection<PaperQualityCheck>({
            collectionPath: PATHS.collections.paperQualityChecks(companyId),
            queryConstraints: [
                { field: "isSoftDeleted", operator: "==" as WhereFilterOp, value: false },
            ]
        });

        return paperQualityChecks;
    }
);

export const addPaperQualityCheckObject = createAsyncThunk(
    "productionStages/addPaperQualityCheckObject",
    async (paperQualityCheck: Omit<PaperQualityCheck, "id">, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected. c:PSL2");
        }

        const paperQualityCheckRef = await setDocumentWithHistory<PaperQualityCheck>({
            path: PATHS.collections.paperQualityChecks(companyId),
            data: paperQualityCheck,
            userId,
        });

        return paperQualityCheckRef;
    }
);

export const updatePaperQualityCheckObject = createAsyncThunk(
    "productionStages/updatePaperQualityCheckObject",
    async (paperQualityCheck: PaperQualityCheck, { getState }) => {
        const state = getState() as RootState;
        const { companyId, userId } = selectSelectedCompanyIdAndUserId(state);

        if (!companyId || !userId) {
            throw new Error("No company or user selected. c:PSL2");
        }

        const oldPaperQualityCheck = state.paperQualityChecks.items.find((item) => item.id === paperQualityCheck.id);

        if (!oldPaperQualityCheck) {
            throw new Error("Paper quality check not found. c:PSL3");
        }

        try {
            await updateDocumentsWithHistory<PaperQualityCheck>([{
                path: PATHS.documents.paperQualityCheck(companyId, paperQualityCheck.id),
                oldData: oldPaperQualityCheck,
                newData: paperQualityCheck,
                userId,
            }]);
            return paperQualityCheck;
        } catch (error) {
            console.error(error);
            throw new Error("Failed to update paper quality check object. c:PSL4");
        }
    }
);

const paperQualityChecksSlice = createSlice({
    name: "paperQualityChecks",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPaperQualityCheckObjects.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchPaperQualityCheckObjects.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = action.payload;
        });
        builder.addCase(fetchPaperQualityCheckObjects.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message ?? null;
        });
        builder.addCase(updatePaperQualityCheckObject.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updatePaperQualityCheckObject.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = state.items.map((item) => item.id === action.payload.id ? action.payload : item);
        });
    }
});

export const { } = paperQualityChecksSlice.actions;
export default paperQualityChecksSlice.reducer;

